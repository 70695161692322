import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// Images
import Navbar from "components/NavBar";
import consumerRoutes from "../../consumerRoutes";

import MKBadge from "components/MKBadge";
import { BackButton } from "components/BackButton";
import { BackToTop } from "components/BackToTop";

import img1 from "assets/images/docs/howUser.png";
import img2 from "assets/images/docs/howConsumer.png";
import { ShowMeLink } from "components/ShowMeLink";

export const HowWeCollect = () => {
  return (
    <>
      <Navbar
        routes={consumerRoutes}
        action={{
          type: "internal",
          route: "/consumer/buyData",
          label: "buy data",
          color: "info",
        }}
        sticky
      />
      <Container>
        <MKBox paddingTop={15}>
          <BackButton />
        </MKBox>
        <Grid container flexDirection="column" alignItems="center" mt={4} spacing={2}>
          <Grid item lg={6} xs={12} sx={{ textAlign: "center", mx: "auto" }}>
            <MKBadge
              variant="contained"
              color="info"
              badgeContent="Bripplex"
              container
              circular
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" mb={1}>
              How we collect the data?{" "}
            </MKTypography>
            <MKTypography variant="body" color="text">
              While the Bripplex app is active and you have authorized them, your device sensors
              will collect and submit data.
            </MKTypography>
          </Grid>
          <Grid item xs={12} width={"100%"} mb={4}>
            <MKTypography variant="h3" mt={4} textAlign="left" mb={4}>
              Bripplex App
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              From the user perspective, things couldn&apos;t be simpler! Bripplex App does all the
              work!
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              Once you authorize your sensors to collect data, the app stores and uploads the files
              to Bripplex.com Files are in the JSON format and you can see examples{" "}
              <a href="/docs/what" target="noopener noreferrer">
                <b>here</b>
              </a>
            </MKTypography>
          </Grid>
          <Grid item xs={12} width={"100%"}>
            <img src={img1} alt={"Bripplex"} loading="lazy" width={"100%"} />
          </Grid>

          <Grid item xs={12} width={"100%"}>
            <MKTypography variant="body" mt={4} textAlign="left">
              Files are cryptographically signed using a key stored on your devices to ensure they
              are not tampered or modified in any way. Ensuring you and the buyers the data is
              legit.
            </MKTypography>

            <MKTypography variant="body" mt={1} textAlign="left">
              At this point, you are the only one who can review the data that is being uploaded.
              Not even us can view it.
            </MKTypography>
          </Grid>
          <Grid
            item
            xs={12}
            // width={"100%"}
            mb={4}
            mt={4}
            textAlign={"center"}
            alignItems={"center"}
          >
            <MKBadge
              variant="contained"
              color="primary"
              badgeContent={
                <>
                  The more sensors you authorize, <br />
                  the more chances your data will be purchased!
                </>
              }
              container
              sx={{ mb: 1, width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} width={"100%"} mb={4}>
            <BackToTop />
            <MKTypography variant="h3" mt={4} textAlign="left" mb={4}>
              Bripplex.com
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              A summary of the data uploaded by <b>Bripplex</b> community is immediatly available on
              our website for potential buyers to review.
              <ShowMeLink href={"/consumer"}>See data already uploaded</ShowMeLink>
            </MKTypography>
            <MKTypography variant="body" mt={2} textAlign="left">
              The summary includes geolocation markers with latitude and longitude and what type of
              sensors are included. (e.g. Accelerometer, Pedometer, Gyroscope, etc. You can review
              all the data we collect{" "}
              <a href="/docs/what">
                <b>here</b>
              </a>{" "}
              )
            </MKTypography>
          </Grid>
          <Grid item xs={12} width={"100%"}>
            <MKTypography variant="body" mt={4} textAlign="left">
              Data consumers that may find your submitted data valuable (researchers,
              metheorologist, application builders, etc) can review this data and decide to purchase
              it. In just a few clicks they create a buy order and purchase it.
            </MKTypography>

            <MKTypography variant="body" mt={1} textAlign="left">
              Many things happen at this time, but most importantly, we paid the devices that got
              their data purchased in crypto. We use stable coin{" "}
              <a href="https://www.circle.com/en/usdc" target="_blank" rel="noopener noreferrer">
                <u>USDC</u>{" "}
              </a>
              (1 U$ = 1 USDC). Bripplex retains a fee of the final payment
            </MKTypography>
          </Grid>
          <Grid item xs={12} width={"100%"}>
            <img src={img2} alt={"Bripplex"} loading="lazy" width={"100%"} />
          </Grid>
          <Grid item xs={12} width={"100%"}>
            <MKTypography variant="body" mt={4} textAlign="left">
              The buyer in exchange receives an NFT (in <i>Polygon</i> blockchain) that proves
              ownership of all the files included in the metadata. In this way, the buyer can
              download all the <i>JSON</i> files purchased as long as remains owner of the NFT.
            </MKTypography>

            <ShowMeLink href={"/docs/payment"}>Read more about Bripplex NFT and payment</ShowMeLink>
          </Grid>
          <Grid item xs={12} width={"100%"}>
            <BackToTop />
          </Grid>
        </Grid>
      </Container>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

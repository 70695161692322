import React, { useEffect, useState } from "react";
import PropTypes, { object } from "prop-types";
import { useSelector } from "react-redux";

import Container from "@mui/material/Container";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";

import "./Map.css";
import { MapLoading } from "./components/MapLoading";
import { MapPolyline } from "./components/MapPolyline";
import { AreaCircle } from "./components/AreaCircle/AreaCircle";
import { MapDrawingTool } from "./components/MapDrawingTool";
import { DataRequestPolygon } from "./components/DataRequestPolygon/DataRequestPolygon";
import { isMobile } from "react-device-detect";

const areaZoomLimit = 10;
const initialAreaRadius = 250000;
const libraries = ["drawing", "geometry"];

export const Map = ({
  withDataRequest,
  withBuyData,
  width = "80vw",
  height = "80vh",
  markers,
  areas,
  mapTypeControl = isMobile ? false : true,
  streetViewControl = false,
}) => {
  const { dataRequests } = useSelector((state) => state.consumer);
  const [areaRadius, setAreaRadius] = useState(initialAreaRadius);
  const [googleMap, setGoogleMap] = useState();
  const [showMarkers, setShowMarkers] = useState(false);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    id: "google-map-script",
    libraries: libraries,
  });

  // we make sure to fit every area in the map
  useEffect(() => {
    if (googleMap) {
      if (areas.length > 0) {
        const myBounds = new window.google.maps.LatLngBounds();
        areas.forEach(({ center, distance }) => {
          var centerCircle = new window.google.maps.Circle({ center, radius: distance });
          myBounds.union(centerCircle.getBounds());
        });
        googleMap.fitBounds(myBounds);
        return;
      } else {
        // we have no areas, we focus on the markers then
        const myBounds = new window.google.maps.LatLngBounds();
        markers.forEach(({ firstLat, firstLng, lastLat, lastLng }) => {
          myBounds.extend({ lat: firstLat, lng: firstLng });
          myBounds.extend({ lat: lastLat, lng: lastLng });
        });
        googleMap.fitBounds(myBounds);
      }
    }
  }, [areas, googleMap]);

  // someone click on an area, we zoom in and center the map
  const areaInfoWindowClick = (id) => {
    const area = areas.find((val) => val.id === id);
    if (!area) return;

    googleMap.setCenter(area.center);
    googleMap.setZoom(areaZoomLimit);
  };
  return (
    <Container>
      {!isLoaded ? (
        <div style={{ height: height, width: width }}>
          <MapLoading />
        </div>
      ) : (
        <>
          <div style={{ height: height, width: width }}>
            <GoogleMap
              options={{ streetViewControl, mapTypeControl }}
              mapTypeId="roadmap"
              onLoad={(map) => {
                setGoogleMap(map);
              }}
              onZoomChanged={() => {
                const currentZoom = googleMap.getZoom();
                if (currentZoom >= 10) setShowMarkers(true);
                else setShowMarkers(false);

                if (currentZoom < 10 && currentZoom > 8) {
                  setAreaRadius(null);
                }
                if (currentZoom < 8) {
                  setAreaRadius(initialAreaRadius);
                }
              }}
              mapContainerClassName="map-container"
              style={{ height: "100%", width: "100%" }}
            >
              {showMarkers
                ? markers.map((val, index) => <MapPolyline marker={val} key={index + "marker"} />)
                : areas.map((area, index) => (
                    <AreaCircle
                      area={area}
                      key={index + "circle"}
                      radius={areaRadius}
                      onZoomHandler={() => areaInfoWindowClick(area.id)}
                    />
                  ))}

              {withDataRequest && <MapDrawingTool isDataRequest={true} isBuyData={false} />}
              {withDataRequest &&
                dataRequests.requests.map((request) => {
                  return !request.delete && <DataRequestPolygon request={request} />;
                })}

              {withBuyData && <MapDrawingTool isDataRequest={false} isBuyData={true} />}
            </GoogleMap>
          </div>
        </>
      )}
    </Container>
  );
};

Map.defaultProps = {
  withDataRequest: false,
  withBuyData: false,
};

Map.propTypes = {
  withDataRequest: PropTypes.bool,
  withBuyData: PropTypes.bool,
  width: PropTypes.any,
  height: PropTypes.any,
  markers: PropTypes.arrayOf(object).isRequired,
  areas: PropTypes.arrayOf(object).isRequired,
  mapTypeControl: PropTypes.bool,
  streetViewControl: PropTypes.bool,
};

import React from "react";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";
import { isValidEmailAddress } from "utils/misc";
import { AreYouSureModal } from "components/AreYouSureModal";
import { useDispatch, useSelector } from "react-redux";
import { db } from "configuration/firebase";
import { showBackdrop } from "reducer/uiReducer";
import { hideBackdrop } from "reducer/uiReducer";
import { showSnackbar } from "reducer/uiReducer";
import { logError } from "utils/errors";

export const DataUsageForm = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { uid } = user || {};
  const [isFormReadOnly, setIsFormReadOnly] = useState(false);
  const minChars = 50; // how many characters I will request for dataUsage
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: { value: "", error: true },
    lastName: { value: "", error: true },
    email: { value: "", error: true },
    company: { value: "", error: true },
    dataUsage: { value: "", error: true },
    www: { value: "", error: true },
    terms: true,
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((current) => ({ ...current, [name]: { value } }));
  };

  useEffect(() => {
    if (user.company) {
      setIsFormReadOnly(true);
      setFormData({
        firstName: { value: user.company.firstName, error: false },
        lastName: { value: user.company.lastName, error: false },
        email: { value: user.company.email, error: false },
        company: { value: user.company.companyName, error: false },
        dataUsage: { value: user.company.dataUsage, error: false },
        www: { value: user.company.www, error: false },
        terms: { value: user.company.acceptedTerms, error: false },
      });
    } else {
      setIsFormReadOnly(false);
    }
  }, [user]);

  function validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  useEffect(() => {
    // first name validation
    setFormData((current) => ({
      ...current,
      firstName: { ...current.firstName, error: formData.firstName.value ? false : true },
    }));

    // first name validation
    setFormData((current) => ({
      ...current,
      lastName: { ...current.lastName, error: formData.lastName.value ? false : true },
    }));

    // email name validation
    setFormData((current) => ({
      ...current,
      email: { ...current.email, error: !isValidEmailAddress(formData.email.value) },
    }));

    // company name validation
    setFormData((current) => ({
      ...current,
      company: { ...current.company, error: formData.company.value ? false : true },
    }));

    // dataUsage name validation
    setFormData((current) => ({
      ...current,
      dataUsage: {
        ...current.dataUsage,
        error: formData.dataUsage.value.length < minChars ? true : false,
      },
    }));

    setFormData((current) => ({
      ...current,
      www: { ...current.www, error: !validURL(formData.www.value) },
    }));
  }, [
    formData.firstName.value,
    formData.lastName.value,
    formData.email.value,
    formData.company.value,
    formData.dataUsage.value,
    formData.www.value,
    formData.terms,
  ]);

  useEffect(() => {
    if (
      formData.firstName.error ||
      formData.lastName.error ||
      formData.email.error ||
      formData.company.error ||
      formData.dataUsage.error ||
      formData.www.error ||
      !formData.terms ||
      !uid
    )
      setIsButtonDisabled(true);
    else setIsButtonDisabled(false);
  }, [
    formData.firstName.error,
    formData.lastName.error,
    formData.email.error,
    formData.company.error,
    formData.dataUsage.error,
    formData.www.error,
    formData.terms,
    uid,
  ]);

  const handleSaveButton = async () => {
    dispatch(showBackdrop());
    try {
      const data = {
        firstName: formData.firstName.value,
        lastName: formData.lastName.value,
        companyName: formData.company.value,
        dataUsage: formData.dataUsage.value,
        www: formData.www.value,
        email: formData.email.value,
        acceptedTerms: formData.terms,
        approved: false,
      };
      await db
        .collection("consumers")
        .doc(uid)
        .set({ company: { ...data } }, { merge: true });

      dispatch(
        showSnackbar({
          severity: "success",
          message: "Profile saved. We will contact you over email once approved",
        })
      );
    } catch (error) {
      await logError("Saving Consumer profile", error);
      dispatch(
        showSnackbar({
          severity: "error",
          message:
            "There was a problem saving your data. Verify you have connection and try again please.",
        })
      );
    } finally {
      dispatch(hideBackdrop());
    }
  };
  return (
    <MKBox component="form" method="post" autoComplete="off">
      <MKBox py={3} px={{ xs: 1, md: 12, lg: 6, xl: 12 }}>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6} lg={12} xl={6}>
            <MKInput
              name={"firstName"}
              disabled={isFormReadOnly}
              variant="standard"
              placeholder="eg. John"
              label="First Name"
              value={formData.firstName.value}
              error={formData.firstName.error}
              InputLabelProps={{ shrink: true }}
              fullWidth
              onChange={handleFormChange}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={12} xl={6}>
            <MKInput
              name={"lastName"}
              disabled={isFormReadOnly}
              variant="standard"
              placeholder="eg. Doe"
              label="Last Name"
              value={formData.lastName.value}
              error={formData.lastName.error}
              InputLabelProps={{ shrink: true }}
              onChange={handleFormChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 1 }}>
            <MKInput
              name={"email"}
              variant="standard"
              type="email"
              disabled={isFormReadOnly}
              value={formData.email.value}
              error={formData.email.error}
              placeholder="eg. john.doe@mycompany.com"
              label="Email Address"
              InputLabelProps={{ shrink: true }}
              onChange={handleFormChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 1 }}>
            <MKInput
              name={"company"}
              variant="standard"
              type="company"
              disabled={isFormReadOnly}
              value={formData.company.value}
              error={formData.company.error}
              placeholder="eg. My company"
              label="Company name"
              InputLabelProps={{ shrink: true }}
              onChange={handleFormChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 1 }}>
            <MKInput
              name={"www"}
              variant="standard"
              type="www"
              disabled={isFormReadOnly}
              value={formData.www.value}
              error={formData.www.error}
              placeholder="www.bripplex.com"
              label="www"
              InputLabelProps={{ shrink: true }}
              onChange={handleFormChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <MKInput
              name={"dataUsage"}
              variant="standard"
              disabled={isFormReadOnly}
              value={formData.dataUsage.value}
              error={formData.dataUsage.error}
              label={`Explain the use you will give to the data you purchase (min ${minChars} chars)`}
              placeholder="eg. I will be using Bripplex community data for methereological analysis on specific regions"
              rows={4}
              InputLabelProps={{ shrink: true }}
              onChange={handleFormChange}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3, ml: -1 }}>
            <Switch
              name="terms"
              id="flexSwitchCheckDefault"
              defaultChecked
              disabled={isFormReadOnly}
              checked={formData.terms}
              onChange={(e) => {
                setFormData((current) => ({ ...current, terms: e.target.checked }));
              }}
            />
            <MKTypography
              component="label"
              variant="button"
              color="text"
              fontWeight="regular"
              htmlFor="flexSwitchCheckDefault"
              sx={{ userSelect: "none", cursor: "pointer" }}
            >
              I agree to the{" "}
              <MKTypography component="a" href="#" variant="button" fontWeight="regular">
                <Link to={"/consumer/terms"} target="_blank" rel="noreferrer noopener">
                  Terms and Conditions
                </Link>
              </MKTypography>
              .
            </MKTypography>
          </Grid>
          <Grid item xs={12}>
            {isFormReadOnly ? (
              <MKTypography variant="body2">
                Do you need to make changes?{" "}
                <Link to={"mailto:contact@bripplex.com"}>Contact us</Link>
              </MKTypography>
            ) : (
              <MKButton
                onClick={() => setShowModal(true)}
                disabled={isButtonDisabled}
                variant="gradient"
                color="dark"
                fullWidth
              >
                Submit
              </MKButton>
            )}
          </Grid>
        </Grid>
      </MKBox>
      <AreYouSureModal
        show={showModal}
        setShow={setShowModal}
        title={"Are you sure?"}
        message={
          <>
            <MKTypography variant={"h5"}>
              Please review your data because you will not be able to change it after submitted.
              <br />
              <br />
            </MKTypography>
            <MKTypography variant={"body2"}>
              Your submitted data will be reviewed by <b>Bripplex</b>.
              <br />
              <br />
              Once approved, you will be notified by email and then you will be able to:
              <br />
              <ul>
                <li>Request the community for new data of missing areas.</li>
                <li>Purchase existing data.</li>
                <li>Sell already purchased data.</li>
                <li>And many more...</li>
              </ul>
            </MKTypography>
          </>
        }
        okButtonMessage={"Save"}
        onAccept={handleSaveButton}
      />
    </MKBox>
  );
};

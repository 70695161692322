import React from "react";
import PropTyes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MKButton from "components/MKButton";
import { Icon } from "@mui/material";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";
export const CopyButton = ({ text }) => {
  const dispatch = useDispatch();
  const handleCopy = () => {
    dispatch(showSnackbar({ severity: "info", message: `Copied ${text} to clipboard.` }));
  };
  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <MKButton variant="text" iconOnly size={"medium"} color="primary">
        <Icon color="primary">content_copy</Icon>
      </MKButton>
    </CopyToClipboard>
  );
};

CopyButton.propTypes = {
  text: PropTyes.string.isRequired,
};

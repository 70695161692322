export const isValidEmailAddress = (address) => {
  const regEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEmail.test(address);
};

export const shortenAddress = (address) => {
  if (!address) return;

  return address.slice(0, 7).concat("...").concat(address.slice(-7));
};

import { db, firebase } from "configuration/firebase";
import { store } from "reducer/store";
import { logError } from "./errors";
import { setOrders } from "reducer/consumerReducer";
import { setWallets } from "reducer/consumerReducer";
import { loadDataRequest } from "reducer/consumerReducer";

export const loadDataRequests = async (uid) => {
  if (!uid) return;

  const snapshot = await db.collection("consumers").doc(uid).collection("dataRequest").get();

  if (snapshot.empty || snapshot.docs.lenght == 0) return;
  const result = [];
  snapshot.docs.forEach((doc) => {
    const data = doc.data();
    result.push(data);
  });
  store.dispatch(loadDataRequest(result));
};

export const getOrders = async (uid) => {
  if (!uid) return;

  try {
    const snapshot = await db
      .collection("consumers")
      .doc(uid)
      .collection("orders")
      .orderBy("status", "asc")
      .get();
    if (snapshot.empty || snapshot.docs.lenght === 0) return;
    const result = [];
    for (const doc of snapshot.docs) {
      const data = doc.data();
      result.push(data);
    }
    store.dispatch(setOrders(result));
  } catch (error) {
    logError("GetOrders", error);
    throw error;
  }
};

export const getWallets = async (uid) => {
  if (!uid) return;
  try {
    const snapshot = await db.collection("consumers").doc(uid).get();
    if (!snapshot.exists) return;

    const result = [];
    const data = snapshot.data();
    const { wallets = [] } = data;

    for (const wallet of wallets) {
      result.push(wallet);
    }
    store.dispatch(setWallets(result));
  } catch (error) {
    logError("GetWallets", error);
    throw error;
  }
};

export const addWallet = async (uid, wallet) => {
  if (!uid) return;
  const ref = db.collection("consumers").doc(uid);
  await ref.set({ wallets: firebase.firestore.FieldValue.arrayUnion(wallet) }, { merge: true });
};

import { createSlice } from "@reduxjs/toolkit";
import { sensorsList } from "utils/sensors";

const initialState = {
  orders: [],
  orderIncludedSensors: sensorsList.reduce((a, b) => ({ ...a, [b.type]: true }), {
    location: true,
  }),

  dataRequests: {
    withUpdates: false,
    requests: [],
  },
  wallets: [],
};

export const consumerSlice = createSlice({
  name: "consumer",
  initialState,
  reducers: {
    loadDataRequest: (state, action) => {
      state.dataRequests.requests = action.payload;
    },
    addDataRequest: (state, action) => {
      state.dataRequests.requests.push(action.payload);
      state.dataRequests.withUpdates = true;
    },
    removeDataRequest: (state, action) => {
      const index = state.dataRequests.requests.findIndex((val) => val.id === action.payload);
      if (index > -1) {
        state.dataRequests.requests[index].delete = true;
      }
      state.dataRequests.withUpdates = true;
    },
    updateDataRequest: (state, action) => {
      state.dataRequests.requests = [
        ...state.dataRequests.requests.filter((val) => val.id !== action.payload.id),
        action.payload,
      ];
      state.dataRequests.withUpdates = true;
    },
    saveRequests: (state) => {
      state.dataRequests.withUpdates = false;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    deleteOrder: (state, action) => {
      state.orders = state.orders.filter((order) => order.id !== action.payload);
    },
    setWallets: (state, action) => {
      state.wallets = action.payload;
    },
    setOrderIncludedSensors: (state, action) => {
      state.orderIncludedSensors = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addDataRequest,
  removeDataRequest,
  updateDataRequest,
  saveRequests,
  setOrders,
  deleteOrder,
  setWallets,
  loadDataRequest,
  setOrderIncludedSensors,
} = consumerSlice.actions;

export default consumerSlice.reducer;

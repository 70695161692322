import React, { useState } from "react";

import PropTypes from "prop-types";
import { Included } from "./Included";
import { Grid } from "@mui/material";

import { db } from "configuration/firebase";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";
import { logError } from "utils/errors";
import { JSONModal } from "components/JSONModal";
import { sensorsList } from "utils/sensors";

export const IncludedList = ({ order, showJSON = true, isNFT = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [jsonEntry, setJsonEntry] = useState({});
  const dispatch = useDispatch();

  const getFirstLocation = async () => {
    const { hash, areaId } = !isNFT ? order.markers[0] : order.metadata.devices[0].locations[0];
    try {
      let locationRef;
      if (!isNFT) {
        locationRef = db
          .collection("data")
          .doc("location")
          .collection("areas")
          .doc(areaId)
          .collection("markers")
          .doc(hash);
      } else {
        locationRef = db
          .collection("nft")
          .doc(order.tokenId.toString())
          .collection("location")
          .doc(hash);
      }
      const snapshot = await locationRef.get();
      if (!snapshot.exists) return; //should not happen

      const data = snapshot.data();
      const json = {
        latitude: data.firstLat,
        longitude: data.firstLng,
        altitude: data.maxAlt,
        speed: data.maxSpeed,
        timestamp: data.firstTimestamp,
      };
      setJsonEntry({ json: json, label: "Location" });
      setShowModal(true);
    } catch (error) {
      logError("Showing first entry", error);
      setShowModal(false);
      dispatch(
        showSnackbar({
          severity: "error",
          message:
            "We have been unable to retrieve the JSON file. Check your connection and try again.",
        })
      );
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getFirstSensor = async (sensorType) => {
    // we have to find in all markers until we found one with the requested sensor
    const { areaId } = !isNFT ? order.markers[0] : 0;
    let query;

    if (!isNFT) {
      query = db
        .collection("data")
        .doc("location")
        .collection("areas")
        .doc(areaId)
        .collection("markers")
        .where(`${sensorType}.rows`, ">", 0)
        .limit(1);
    } else {
      // this is an NFT so we change the source of data
      query = db
        .collection("nft")
        .doc(order.tokenId.toString())
        .collection("location")
        .where(`${sensorType}.rows`, ">", 0)
        .limit(1);
    }
    const snapshot = await query.get();
    if (snapshot.empty) return; // this could happen if there is no sensor type in the area of the first marker.

    let sensorQuery;

    if (!isNFT) {
      sensorQuery = db
        .collection("data")
        .doc("location")
        .collection("areas")
        .doc(areaId)
        .collection("markers")
        .doc(snapshot.docs[0].id)
        .collection(sensorType)
        .limit(1);
    } else {
      sensorQuery = db
        .collection("nft")
        .doc(order.tokenId.toString())
        .collection("location")
        .doc(snapshot.docs[0].id)
        .collection(sensorType)
        .limit(1);
    }
    const sensorSnapshot = await sensorQuery.get();

    if (sensorSnapshot.empty) return;

    const doc = sensorSnapshot.docs[0];
    const sensorDoc = doc.data();
    let json = {};
    if (sensorType === "microphone") {
      json = {
        dBFS: sensorDoc.minDBFS,
        timestamp: sensorDoc.firstTimestamp,
      };
    }
    if (
      sensorType === "accelerometer" ||
      sensorType === "gyroscope" ||
      sensorType === "magnetometer"
    ) {
      json = {
        x: sensorDoc.minX,
        y: sensorDoc.minY,
        z: sensorDoc.minZ,
        timestamp: sensorDoc.firstTimestamp,
      };
    }
    if (sensorType === "light") {
      json = {
        illuminance: sensorDoc.minIlluminance,
        timestamp: sensorDoc.firstTimestamp,
      };
    }
    if (sensorType === "pedometer") {
      json = {
        steps: sensorDoc.minSteps,
        timestamp: sensorDoc.firstTimestamp,
      };
    }
    if (sensorType === "barometer") {
      json = {
        pressure: sensorDoc.minPressure,
        relativeAltitude: sensorDoc.minRelativeAltitude,
        timestamp: sensorDoc.firstTimestamp,
      };
    }
    if (sensorType === "deviceMotion") {
      json = {
        accelerationX: sensorDoc.minAccelerationX,
        accelerationY: sensorDoc.minAccelerationY,
        accelerationZ: sensorDoc.minAccelerationZ,
        accelerationGX: sensorDoc.minAccelerationGX,
        accelerationGY: sensorDoc.minAccelerationGY,
        accelerationGZ: sensorDoc.minAccelerationGZ,
        orientation: sensorDoc.minOrientation,
        rotationAlpha: sensorDoc.minRotationAlpha,
        rotationBeta: sensorDoc.minRotationBeta,
        rotationGamma: sensorDoc.minRotationGamma,
        rotationRateAlpha: sensorDoc.minRotationRateAlpha,
        rotationRateBeta: sensorDoc.minRotationRateBeta,
        rotationRateGamma: sensorDoc.minRotationRateGamma,
        timestamp: sensorDoc.firstTimestamp,
      };
    }
    if (sensorType === "carrier") {
      json = {
        carrierName: sensorDoc.firstCarrierName,
        allowsVOIP: sensorDoc.firstAllowsVOIP,
        isoCountryCode: sensorDoc.firstIsoCountryCode,
        mobileCountryCode: sensorDoc.firstMobileCountryCode,
        mobileNetworkCode: sensorDoc.firstMobileNetworkCode,
        mobileNetworkOperator: sensorDoc.firstMobileNetworkOperator,
        timestamp: sensorDoc.firstTimestamp,
      };
    }
    if (sensorType === "compass") {
      json = {
        accuracy: sensorDoc.minAccuracy,
        magHeading: sensorDoc.minMagHeading,
        trueHeading: sensorDoc.minTrueHeading,
        timestamp: sensorDoc.firstTimestamp,
      };
    }
    if (sensorType === "installedApps") {
      json = {
        amount: sensorDoc.amount,
        list: "[Array of installed apps]",
        timestamp: sensorDoc.firstTimestamp,
      };
    }

    setJsonEntry({ json: json, label: capitalizeFirstLetter(sensorType) });
    setShowModal(true);
  };
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Included
            label="Location"
            description=" with latitude, longitude, speed and altitude."
            rows={order.location.rows}
            files={order.location.files}
            onViewHandler={showJSON && getFirstLocation}
            icon={"location_on"}
          />
        </Grid>
        {sensorsList.map((sensor) => {
          if (order.includedSensors[sensor.type])
            return (
              order[sensor.type]?.rows > 0 && (
                <Grid item xs={12}>
                  <Included
                    label={sensor.label}
                    description={sensor.description}
                    rows={order[sensor.type].rows}
                    files={order[sensor.type].files}
                    onViewHandler={
                      showJSON &&
                      (() => {
                        getFirstSensor(sensor.type);
                      })
                    }
                    icon={sensor.icon}
                  />
                </Grid>
              )
            );
        })}
      </Grid>
      <JSONModal
        show={showModal}
        setShow={setShowModal}
        json={jsonEntry.json}
        label={jsonEntry.label}
      />
    </>
  );
};

IncludedList.propTypes = {
  order: PropTypes.object.isRequired,
  showJSON: PropTypes.bool,
  isNFT: PropTypes.bool,
};

import React from "react";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import { Button, Card, Grid, Icon } from "@mui/material";
import MKTypography from "components/MKTypography";
import { CopyButton } from "components/CopyButton";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import { useDispatch, useSelector } from "react-redux";
import { setWallets } from "reducer/consumerReducer";
import { db } from "configuration/firebase";
import { showSnackbar } from "reducer/uiReducer";
import { shortenAddress } from "utils/web3";

export const LinkedWallet = ({
  address,
  showDelete = true,
  isSelectable = false,
  selected,
  setSelected,
}) => {
  const { wallets } = useSelector((state) => state.consumer);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    const newWallets = [...wallets.filter((val) => val !== address)];
    dispatch(setWallets(newWallets));
    try {
      await db.collection("consumers").doc(user.uid).set({ wallets: newWallets }, { merge: true });
    } catch (error) {
      dispatch(
        showSnackbar({
          severity: "error",
          message: "There was an error removing the wallet. Please refresh and try again.",
        })
      );
    }
  };

  const handleSelect = () => {
    if (isSelectable) setSelected(true);
  };

  return (
    <Card
      elevation={4}
      sx={{
        boxShadow: 4,
        padding: { xs: 0, md: 4 },
        border: selected && "4px solid green",
      }}
      fullWidth
      component={isSelectable && Button}
      onClick={handleSelect}
    >
      <Grid container direction={"row"} alignItems="center" justifyContent={"space-between"}>
        <Grid item padding={1}>
          <MKTypography variant={isMobile ? "body2" : "h5"} textAlign="left">
            {isMobile ? shortenAddress(address) : address} <CopyButton text={address} />
          </MKTypography>
        </Grid>
        {showDelete && (
          <Grid item>
            <MKBox display="flex" alignItems="center">
              <MKButton circle onClick={handleDelete} disabled={wallets.length === 1}>
                <Icon size={isMobile ? "small" : "large"}>delete</Icon>
              </MKButton>
            </MKBox>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

LinkedWallet.propTypes = {
  address: PropTypes.string.isRequired,
  showDelete: PropTypes.bool,
  isSelectable: PropTypes.bool,
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
};

import React, { useRef } from "react";
import moment from "moment";
import getAreaOfPolygon from "geolib/es/getAreaOfPolygon";
import PropTypes from "prop-types";
import { DrawingManager } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { ReviewDataModal } from "pages/Consumer/pages/BuyData/components/ReviewDataModal";
import { useState } from "react";
import { addDataRequest } from "reducer/consumerReducer";
import { showSnackbar } from "reducer/uiReducer";

const MAX_AREA = 300000000000;

export const MapDrawingTool = ({ isDataRequest, isBuyData }) => {
  const { dataRequests } = useSelector((state) => state.consumer);
  const [showBuyDataModal, setShowBuyDataModal] = useState(false);
  const dispatch = useDispatch();
  const shapeRef = useRef();

  const createRequest = () => {
    const paths = shapeRef.current.getPaths();
    const values = [];
    paths.getArray()[0].forEach((val) => {
      values.push({ lat: val.lat(), lng: val.lng() });
    });

    const area = getAreaOfPolygon(values);
    if (area > MAX_AREA) {
      dispatch(
        showSnackbar({
          severity: "info",
          message: `The area of the data request is too big. Try making it smaller.`,
        })
      );
      shapeRef.current.setMap(null);
      return;
    }

    const data = {
      id: shapeRef.current.id,
      path: values,
      isNotified: false,
      creationTimestamp: moment().unix(),
    };
    dispatch(addDataRequest(data));
    shapeRef.current.setMap(null);
    // by adding it on state, the <MapPolygon/>  is showing it on screen.
  };

  const handleCloseBuyModal = () => {
    setShowBuyDataModal(false);
    shapeRef.current.setMap(null);
  };
  return (
    <>
      <DrawingManager
        options={{
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
          },
          polygonOptions: {
            editable: false,
            draggable: false,
          },
        }}
        onPolygonComplete={(shape) => {
          // we already have a shape that is not saved, we don't allow a new one
          if (isDataRequest && dataRequests.withUpdates) {
            dispatch(
              showSnackbar({
                severity: "error",
                message: "You have pending changes. Save them before adding a new shape.",
              })
            );
            shape.setMap(null);
            return;
          }

          if (isDataRequest && dataRequests.requests.length > 4) {
            dispatch(
              showSnackbar({
                severity: "error",
                message:
                  "You exeeded the amount of data requests available. If you need more please contact support@bripplex.com",
              })
            );
            shape.setMap(null);
            return;
          }
          shapeRef.current = shape;
          shapeRef.current.id = crypto.randomUUID();

          if (isDataRequest) {
            createRequest();
          }

          if (isBuyData && shapeRef.current) {
            setShowBuyDataModal(true);
          }
        }}
      />
      <ReviewDataModal
        show={showBuyDataModal}
        onClose={handleCloseBuyModal}
        polygon={shapeRef.current}
      />
    </>
  );
};

MapDrawingTool.propTypes = {
  isDataRequest: PropTypes.bool.isRequired,
  isBuyData: PropTypes.bool.isRequired,
};

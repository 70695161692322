import { configureStore } from "@reduxjs/toolkit";

// My reducers
import authReducer from "./authReducer";
import uiReducer from "./uiReducer";
import sensorReducer from "./sensorReducer";
import consumerReducer from "./consumerReducer";
import { sensorsMiddleware } from "./sensorsMiddleware";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
    sensor: sensorReducer,
    consumer: consumerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(sensorsMiddleware.middleware), // we are storing classes, so we disable serializable constraint
});

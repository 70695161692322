import { db, storage } from "configuration/firebase";
import { setAreas } from "reducer/sensorReducer";
import { addMarkers } from "reducer/sensorReducer";
import { setStats } from "reducer/sensorReducer";
import { store } from "reducer/store";

const batchAmount = 1000;

// load data stats into store
export const getDataStats = async () => {
  const ref = db.collection("data").doc("stats");
  const snapshot = await ref.get();
  const data = snapshot.data();
  const total = sensorsList.reduce(
    (value, sensor) => value + data.uploads[sensor.type] || 0,
    data.uploads.location || 0
  );

  data.uploads.total = total;
  store.dispatch(setStats(data));
};

// load markers into store with pagination
export const loadMarkers = async (areaId) => {
  const snapshot = await getSnapshot();
  await getMarkersFromSnapshot(areaId, snapshot);
};

const getMarkersFromDB = async (areaId) => {
  const query = db
    .collection("data")
    .doc("location")
    .collection("areas")
    .doc(areaId)
    .collection("markers")
    .limit(batchAmount);
  let snapshot = await query.get();

  if (snapshot.docs.length === 0) return;

  addMarkersToStore(snapshot);

  // we have enought, we are done. We continue with next area
  if (snapshot.docs.length < batchAmount) return;

  // there are more to get, we get them
  await loadAdditionalMarkers(snapshot.docs[snapshot.docs.length - 1]);
};

const getMarkersFromSnapshot = async (areaId, snapshot) => {
  if (snapshot) {
    const myArea = snapshot.areas.find((val) => val.id === areaId);
    if (myArea) {
      // we do have a snapshot for this area, so we get the values out of it.
      for (const marker of myArea.markers) {
        store.dispatch(addMarkers(marker));
      }

      // now we load any marker that might have been added after the snapshot timestamp
      await getMarkersAfterSnapshot(areaId, myArea.lastUploadTimestamp);
    }
  } else {
    // we didn't get the snapshot, so we load them from db
    console.log(`No snapshot located. loading markers from db`);
    await getMarkersFromDB(areaId);
  }
};

const getMarkersAfterSnapshot = async (areaId, timestamp) => {
  const query = db
    .collection("data")
    .doc("location")
    .collection("areas")
    .doc(areaId)
    .collection("markers")
    .where("uploadTimestamp", ">", timestamp);
  const result = await query.get();
  console.log(`we got ${result.docs.length} docs after the snapshot for area ${areaId}`);
  if (result.empty) return;

  for (const doc of result.docs) {
    const marker = doc.data();
    store.dispatch(addMarkers(marker));
  }
};

const getSnapshot = async () => {
  let json;
  try {
    const fileRef = storage.ref("markersSnapshot/snapshot.json");
    const downloadURL = await fileRef.getDownloadURL();
    const response = await fetch(downloadURL);
    if (response.ok) {
      json = await response.json();
    }
  } catch (error) {
    console.log(`unable to load snapshot ${error}`);
  }

  return json;
};

export const loadAreas = async () => {
  const query = db.collection("data").doc("location").collection("areas");
  let snapshot = await query.get();

  if (snapshot.docs.length === 0) return;
  const result = [];
  for (const doc of snapshot.docs) {
    const data = doc.data();
    result.push(data);
  }
  store.dispatch(setAreas(result));
};

const loadAdditionalMarkers = async (lastDoc, areaId) => {
  const next = db
    .collection("data")
    .doc("location")
    .collection("areas")
    .doc(areaId)
    .collection("markers")
    .limit(batchAmount)
    .startAfter(lastDoc);

  const snapshot = await next.get();
  if (snapshot.docs.length > 0) {
    addMarkersToStore(snapshot);
    await loadAdditionalMarkers(snapshot.docs[snapshot.docs.length - 1]);
  }
};

const addMarkersToStore = (snapshot) => {
  snapshot.docs.forEach((doc) => {
    const data = doc.data();
    store.dispatch(addMarkers(data));
  });
};

export const sensorsList = [
  {
    type: "accelerometer",
    label: "Accelerometer",
    description: " in three-dimensional space. ",
    icon: "zoom_out_map",
  },
  {
    type: "barometer",
    label: "Barometer",
    description: " which is measured in hectopascals (hPa). ",
    icon: "air",
  },
  {
    type: "deviceMotion",
    label: "Device Motion",
    description: " in terms of three axes that run through a device. ",
    icon: "open_with",
  },
  {
    type: "gyroscope",
    label: "Gyroscope",
    description: " rotation in three-dimensional space. ",
    icon: "zoom_in_map",
  },
  {
    type: "light",
    label: "Light",
    description: " capturing ambient light in lux (lx). ",
    icon: "light_mode",
  },
  {
    type: "magnetometer",
    label: "Magnetometer",
    description: " magnetic field measured in microtesla. ",
    icon: "close_fullscreen",
  },
  {
    type: "pedometer",
    label: "Pedometer",
    description: " counting the number of steps taken. ",
    icon: "directions_walk",
  },
  {
    type: "microphone",
    label: "Microphone",
    description: " capturing dBFS values of the surrouding environment. ",
    icon: "mic_none",
  },
  {
    type: "carrier",
    label: "Carrier",
    description: " capturing carrier and service provider information. ",
    icon: "cell_tower",
  },
  {
    type: "compass",
    label: "Compass",
    description: " capturing magnetic and true direction in degrees. ",
    icon: "explore",
  },
  {
    type: "installedApps",
    label: "Installed Apps",
    description: " listing the applications installed on the device. ",
    icon: "apps",
  },
];

import { CircularProgress } from "@mui/material";
import MKBox from "components/MKBox";
import { db } from "configuration/firebase";
import { NFTContainer } from "pages/Consumer/pages/MyAccount/components/MyFiles/components/NFTContainer";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const NFT = () => {
  const { tokenId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [nft, setNft] = useState();

  const getNFT = async () => {
    try {
      const snapshot = await db.collection("nft").doc(tokenId).get();
      if (!snapshot.exists) return;

      const data = snapshot.data();
      setNft(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (tokenId) {
      getNFT();
    }
  }, [tokenId]);

  return (
    <div>
      {isLoading ? (
        <MKBox display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </MKBox>
      ) : (
        <>
          {nft ? <NFTContainer nft={nft} showDownload={false} /> : <div>NFT does not exists!</div>}
        </>
      )}
    </div>
  );
};

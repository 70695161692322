/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useCallback, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";

// Material Kit 2 PRO React consumerRoutes
import consumerRoutes from "consumerRoutes";
import { Home } from "pages/Home";
import Consumer from "pages/Consumer";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "configuration/firebase";
import { setUser } from "reducer/authReducer";
import SignInCover from "pages/Authentication/SignIn/Cover";
import { CustomSnackbar } from "components/CustomSnackbar";
import SignUpCoverPage from "layouts/authentication/sign-up/cover";
import ResetPasswordPage from "layouts/authentication/reset-password/cover";

import { getDataStats } from "utils/sensors";
import { MyAccount } from "pages/Consumer/pages/MyAccount";
import { AuthWrapper } from "routes/AuthWrapper";
import { Terms } from "pages/Consumer/pages/Terms";
import { DataPrivacyTerms } from "pages/DataPrivacyTerms";
import { CustomBackdrop } from "components/CustomBackdrop";
import { registerCompanyChanges } from "auth/auth";
import { loadAreas } from "utils/sensors";
import { BuyData } from "pages/Consumer/pages/BuyData";
import { loadDataRequests } from "utils/consumers";
import { getOrders } from "utils/consumers";
import { getWallets } from "utils/consumers";
import { PaymentDone } from "pages/Consumer/pages/PaymentDone";
import { NFT } from "pages/NFT";
import { WhatData } from "pages/Docs/WhatData";
import { HowWeCollect } from "pages/Docs/HowWeCollect";
import { HowWePay } from "pages/Docs/HowWePay";
import { verifyEmailAndPassword } from "auth/auth";
import { EmailVerification } from "pages/EmailVerification";
import { EULA } from "pages/Docs/EULA";
import { MobileTerms } from "pages/Docs/MobileTerms";
import { Admin } from "pages/Admin";
import { WalletDoc } from "pages/Docs/WalletDoc";

export default function App() {
  const { areas } = useSelector((state) => state.sensor);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  auth.onAuthStateChanged(async (user) => {
    if (user) {
      const { providerData } = user;
      // if the user is email and is not verified, then we ask for verification
      if (providerData.length > 0 && providerData[0].providerId === "password") {
        if (!user.emailVerified) {
          const success = await verifyEmailAndPassword();
          if (success) navigate("/emailVerification");
        }
      }
    }

    dispatch(
      setUser({
        uid: user?.uid,
        displayName: user?.displayName || user?.email ? user?.email : user?.uid,
      })
    );
    if (user?.uid) {
      await loadDataRequests(user.uid);
      await getOrders(user.uid);
      await getWallets(user.uid);
      await registerCompanyChanges(user.uid);
    }
  });

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const loadingAsyncData = useCallback(async () => {
    if (areas.length === 0) {
      await loadAreas();
      await getDataStats();
    }
  }, [loadAreas, getDataStats]);

  useEffect(() => {
    loadingAsyncData();
  }, [loadingAsyncData]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.private) {
        return (
          <Route path={route.route} element={<AuthWrapper />} key={route.key + "private"}>
            <Route exact path={route.route} element={route.component} key={route.key} />;
          </Route>
        );
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackbar />
      <CssBaseline />
      <Routes>
        {getRoutes(consumerRoutes)}
        <Route path="/" element={<Home />} />
        <Route path="/consumer" element={<Consumer />} />

        <Route path="/consumer/myAccount" element={<AuthWrapper />}>
          <Route path="/consumer/myAccount" element={<MyAccount />} />
          <Route path="/consumer/myAccount/:tab" element={<MyAccount />} />
        </Route>

        <Route path="/consumer/buyData" element={<AuthWrapper />}>
          <Route path="/consumer/buyData" element={<BuyData />} />
        </Route>

        <Route path="/consumer/paymentDone" element={<AuthWrapper />}>
          <Route path="/consumer/paymentDone" element={<PaymentDone />} />
        </Route>

        {/* Admin paths */}
        <Route path="/admin/:section" element={<AuthWrapper />}>
          <Route path="/admin/:section" element={<Admin />} />
        </Route>

        <Route path="/nft/:tokenId" element={<NFT />} />

        <Route path="/consumer/terms" element={<Terms />} />
        <Route path="/dataPrivacy" element={<DataPrivacyTerms />} />
        <Route path="/signin" element={<SignInCover />} />
        <Route path="/signup" element={<SignUpCoverPage />} />
        <Route path="/password" element={<ResetPasswordPage />} />

        <Route path="/docs/what" element={<WhatData />} />
        <Route path="/docs/how" element={<HowWeCollect />} />
        <Route path="/docs/payment" element={<HowWePay />} />
        <Route path="/docs/EULA" element={<EULA />} />
        <Route path="/docs/Wallet" element={<WalletDoc />} />
        <Route path="/docs/appTerms" element={<MobileTerms />} />
        <Route path="/emailVerification" element={<EmailVerification />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <CustomBackdrop />
    </ThemeProvider>
  );
}

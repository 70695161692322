import React, { useEffect } from "react";
import MKButton from "components/MKButton";

import { useSDK } from "@metamask/sdk-react";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useNavigate } from "react-router-dom";
import { authStartSignUpWallet } from "auth/auth";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";

export const WalletAuthButton = ({ ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { sdk, connected, connecting, provider, chainId } = useSDK();

  useEffect(() => {
    if (user?.uid) navigate(-1);
  }, [user]);

  const handleWalletAuth = async () => {
    const accounts = await sdk?.connect();
    if (accounts.length > 0) {
      try {
        await authStartSignUpWallet(accounts[0], provider);
      } catch (error) {
        dispatch(
          showSnackbar({
            severity: "error",
            message:
              "There was an error signing you up with a web3 wallet. Please refresh and try again",
          })
        );
      }
    }
  };

  return (
    <MKButton
      variant="contained"
      size="medium"
      color="info"
      circular
      iconOnly
      {...rest}
      onClick={handleWalletAuth}
    >
      <AccountBalanceWalletIcon color="inherit" />
    </MKButton>
  );
};

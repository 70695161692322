import React from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { authStartLogOut } from "auth/auth";
import { NavBarItem } from "components/NavBar/components/NavBarItem";

export const AccountMenu = () => {
  const { user } = useSelector((state) => state.auth);
  const { uid, displayName } = user || {};
  const navigate = useNavigate();

  return (
    <>
      {!uid ? (
        <>
          <NavBarItem
            name={"Sign in"}
            description={"Sign in with your existing account"}
            onClick={() => navigate("/signin")}
          />
          <NavBarItem
            name={"Sign Up"}
            description={"Create a new account"}
            onClick={() => navigate("/signup")}
          />
          <NavBarItem
            name={"Reset password"}
            description={"Create a new password for an existing account"}
            onClick={() => navigate("/password")}
          />
        </>
      ) : (
        <>
          <NavBarItem
            name={"My account"}
            description={"Review your profile, orders or purchased files"}
            onClick={() => navigate("/consumer/myAccount")}
          />
          <NavBarItem
            name={"Sign out"}
            description={`Log out from ${displayName || uid}`}
            onClick={() => {
              authStartLogOut();
            }}
          />
        </>
      )}
    </>
  );
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { AreYouSureModal } from "components/AreYouSureModal";
import { useDispatch, useSelector } from "react-redux";
import { db } from "configuration/firebase";
import { showSnackbar } from "reducer/uiReducer";
import MKButton from "components/MKButton";
import { deleteOrder } from "reducer/consumerReducer";

export const CancelOrderButton = ({ id }) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const handleClick = () => {
    setShowModal(true);
  };

  const handleCancel = async () => {
    try {
      await db.collection("consumers").doc(user.uid).collection("orders").doc(id).delete();
      dispatch(showSnackbar({ severity: "success", message: `Order ${id} cancelled.` }));
      dispatch(deleteOrder(id));
    } catch (error) {
      dispatch(
        showSnackbar({
          severity: "error",
          message: `There was an error cancelling the order. Please refresh to try again.`,
        })
      );
    }
  };
  return (
    <>
      <MKButton variant="contained" color="warning" onClick={handleClick} fullLenght>
        Cancel order
      </MKButton>
      <AreYouSureModal
        show={showModal}
        setShow={setShowModal}
        title="Cancel Order?"
        message={
          <p>
            Are you sure you want to cancel order <b>{id}</b>?
          </p>
        }
        okButtonMessage={"Cancel Order"}
        onAccept={handleCancel}
      />
    </>
  );
};

CancelOrderButton.propTypes = {
  id: PropTypes.string.isRequired,
};

import React from "react";
import MKButton from "components/MKButton";
import FacebookIcon from "@mui/icons-material/Facebook";

import { authStartSignUpUserMedia } from "auth/auth";
import { useNavigate } from "react-router-dom";

export const FacebookAuthButton = ({ ...rest }) => {
  const navigate = useNavigate();
  const handleGoogleAuth = async () => {
    await authStartSignUpUserMedia("facebook", true);
    // navigate("/consumer");
  };
  return (
    <MKButton
      variant="contained"
      size="medium"
      color="info"
      circular
      iconOnly
      {...rest}
      onClick={handleGoogleAuth}
    >
      <FacebookIcon color="inherit" />
    </MKButton>
  );
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  snackbar: {
    open: false,
    severity: "success",
    message: "Initial message",
  },
  showBackdrop: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    showSnackbar: (state, action) => {
      state.snackbar = {
        open: true,
        severity: action.payload.severity,
        message: action.payload.message,
      };
    },
    closeSnackbar: (state) => {
      state.snackbar = {
        open: false,
      };
    },
    showBackdrop: (state) => {
      state.showBackdrop = true;
    },
    hideBackdrop: (state) => {
      state.showBackdrop = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showSnackbar, closeSnackbar, showBackdrop, hideBackdrop } = uiSlice.actions;

export default uiSlice.reducer;

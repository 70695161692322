import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import MKBox from "components/MKBox";

export const CustomBackdrop = () => {
  const { showBackdrop } = useSelector((state) => state.ui);

  return (
    <Backdrop sx={{ color: "#fff", zIndex: 99 }} open={showBackdrop}>
      <MKBox display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress color="info" />
      </MKBox>
    </Backdrop>
  );
};

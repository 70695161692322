import React, { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import image from "assets/images/examples/blog2.jpg";
import { Link, useSearchParams } from "react-router-dom";
import { CircularProgress, Container } from "@mui/material";
import { db } from "configuration/firebase";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";
import { logError } from "utils/errors";
import MKButton from "components/MKButton";

export const PaymentDone = () => {
  const [isVerified, setIsVerified] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  let [searchParams, setSearchParams] = useSearchParams();

  const verifyOrder = async () => {
    try {
      const orderId = searchParams.get("orderId");
      const snapshot = await db
        .collection("consumers")
        .doc(user.uid)
        .collection("orders")
        .doc(orderId)
        .get();
      if (!snapshot.exists) {
        dispatch(
          showSnackbar({
            severity: "warning",
            message:
              "We have been unable to verify your order. Please verify your order at My Account.",
          })
        );
      }

      const data = snapshot.data();
      if (data.status === 2) setIsVerified(true);
    } catch (error) {
      logError("verifyOrder", error);
      dispatch(
        showSnackbar({
          severity: "warning",
          message:
            "We have been unable to verify your order. Please verify your order at My Account.",
        })
      );
    }
  };

  useEffect(() => {
    verifyOrder();
  }, []);

  return (
    <Container>
      {isVerified ? (
        <MKBox component="section" py={3} mt={14}>
          <MKBox bgColor="grey-100" py={12} px={{ xs: 3, lg: 0 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} xl={6} ml="auto">
                <MKTypography variant="h4" mb={1}>
                  Congratulations!
                </MKTypography>
                <MKTypography variant="body1" color="text" mb={3}>
                  Your order has been completed and now is being processed. A lot is going on behind
                  the scenes!
                </MKTypography>
                <MKTypography variant="body1" color="text" mb={3}>
                  1. We are generating the metadata of all the data files included in your purchase
                  <br />
                  2. Issuing to your address a new NFT in blockchain that includes that metadata.
                  This proves you are the legit owner!
                  <br />
                  3. Granting access to the JSON files you purchased to the owner of the NFT
                </MKTypography>

                <MKBox mt={4} textAlign="center">
                  <MKButton
                    variant="contained"
                    color={"primary"}
                    component={Link}
                    to="/consumer/myAccount/myOrders"
                  >
                    Review your orders
                  </MKButton>
                </MKBox>
              </Grid>
              <Grid item xs={12} xl={4} position="relative">
                <MKBox
                  component="img"
                  src={image}
                  alt="image"
                  maxWidth="18.75rem"
                  width="100%"
                  borderRadius="lg"
                  shadow="xl"
                  mt={-24}
                  display={{ xs: "none", lg: "block" }}
                />
              </Grid>
            </Grid>
          </MKBox>
        </MKBox>
      ) : (
        <MKBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", height: "100vh" }}
        >
          <MKBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          ></MKBox>
          <CircularProgress />
        </MKBox>
      )}
    </Container>
  );
};

import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import MKTypography from "components/MKTypography";
import { Rating } from "@mui/material";
import MKBox from "components/MKBox";
import { SensorCheck } from "../SensorCheck";
import { sensorsList } from "utils/sensors";

export const DataQualityIndicator = ({ data }) => {
  const starIncrement = 0.5; // right now we have 12 sensors which give us a precision of 5/12 0.4....
  // with 15 sensors I might switch to a precision of 0.25, which is allowed

  const [value, setValue] = useState(starIncrement); // we start at 0.5 for the location
  const increaseValue = () => {
    setValue((current) => current + starIncrement);
  };

  useEffect(() => {
    sensorsList.map((sensor) => {
      data[sensor.type]?.rows && increaseValue();
    });
  }, [data]);
  return (
    <MKBox>
      <MKTypography variant="body1">Data diversity</MKTypography>
      <Rating name="size-small" value={value} precision={starIncrement} readOnly size="small" />
      <MKTypography variant="body2">Includes:</MKTypography>
      <SensorCheck name={"Location"} />
      {sensorsList.map(
        (sensor) =>
          data[sensor.type]?.rows > 0 && <SensorCheck name={sensor.label} key={sensor.type} />
      )}
    </MKBox>
  );
};

DataQualityIndicator.propTypes = {
  data: PropTypes.object.isRequired,
};

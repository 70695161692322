import React from "react";
import PropTypes from "prop-types";
import MKTypography from "components/MKTypography";
import { Icon } from "@mui/material";
export const OrderStatus = ({ status = 0 }) => {
  // Order status are
  // 0 In Progress: order has no price defined yet
  // 1 Ready for checkout: order has a price define and the user can start checkout
  // 2 Completed: order has been paid
  return (
    <MKTypography variant={"body"}>
      Status:
      {status === 0 && (
        <>
          <MKTypography color="success" display="inline">
            <Icon>sync</Icon>
            <b>In Progress</b>
          </MKTypography>
        </>
      )}
      {status === 1 && (
        <>
          <MKTypography color="secondary" display="inline">
            <Icon>paid</Icon>
            <b>Ready for payment</b>
          </MKTypography>
        </>
      )}
      {status > 1 && (
        <>
          <MKTypography color="info" display="inline">
            <Icon>check</Icon>
            <b>Completed</b>
          </MKTypography>
        </>
      )}
    </MKTypography>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.number.isRequired,
};

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";

export const ProfileNotReady = () => {
  return (
    <MKBox position="relative" variant="gradient" bgColor="dark" mt={-2} py={2} mx={-2}>
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.2}
        display={{ xs: "none", md: "block" }}
      />
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{ pt: 8, pb: 5, position: "relative", zIndex: 3 }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <MKBadge
              badgeContent="Atention"
              variant="contained"
              color="white"
              size="sm"
              container
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" color="white" mb={1}>
              Your profile is not ready
            </MKTypography>
            <MKTypography variant="body1" color="white" fontWeight="light">
              Before you can request or buy data, you must complete your profile
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 2, mb: 4 }} justifyContent={"center"}>
          <Grid item xs={12} md={4}>
            <SimplePricingCard
              variant="gradient"
              color="dark"
              title="Action required"
              description="Complete your profile to be able to request and buy data"
              //   price={{ value: "$499", type: "year" }}
              action={{ type: "internal", route: "/consumer/myAccount", label: "Go to profile" }}
              specifications={[
                "Submit company data usage intention",
                "Connect a Web3 wallet",
                "Review your purchase orders",
                "Access your purchased data",
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

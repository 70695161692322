/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import consumerRoutes from "consumerRoutes";
import footerRoutes from "footer.routes";
import Navbar from "components/NavBar";
import { BackButton } from "components/BackButton";

export const Terms = () => {
  return (
    <>
      <Navbar
        routes={consumerRoutes}
        action={{
          type: "external",
          route: "/consumer/buyData",
          label: "buy now",
          color: "dark",
        }}
        transparent
        light={false}
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <MKBox pb={6}>
                <BackButton />
              </MKBox>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Data consumer terms and conditions
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: July 2024
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Welcome to <b>Bripplex</b>. By accessing or using our website and/or mobile
                    application (collectively referred to as the &ldquo;Service&ldquo;), you agree
                    to comply with and be bound by the following terms and conditions of use. Please
                    review these terms carefully before accessing or using the Service. If you do
                    not agree to these terms, you may not access or use the Service.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    1. Acceptance of Terms
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    By registering as a data consumer on our website, you acknowledge that you have
                    read, understood, and agree to be bound by these terms and conditions, as well
                    as any additional terms and policies referenced herein or available by
                    hyperlink.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    2. Data Usage and Consent
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        You understand and consent to the collection, processing, and storage of
                        sensor data, including but not limited to location, gyroscope, microphone,
                        light, pedometer, and barometer data, from user devices by <b>Bripplex</b>{" "}
                        for the purpose of providing our services.
                      </li>
                      <br />
                      <li>
                        You agree that the data provided by <b>Bripplex</b> is for informational and
                        analytical purposes only. <b>Bripplex</b> shall not be responsible for any
                        consequences resulting from the use or misuse of this data by data
                        consumers.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    3. Registration and Account Security
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        You agree to provide accurate and complete information when registering for
                        an account on our website.
                      </li>
                      <br />
                      <li>
                        You are responsible for maintaining the confidentiality of your account
                        credentials and for all activities that occur under your account.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    4. Payment and Fees
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        Data consumers agree to pay the specified fees for accessing and utilizing
                        sensor data provided by <b>Bripplex</b>. Payment terms and conditions will
                        be outlined separately and agreed upon prior to accessing the data.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    5. Intellectual Property
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        All content and materials provided through the Service, including but not
                        limited to software, text, graphics, logos, and images, are the property of
                        {` `}
                        <b>Bripplex</b> or its licensors and are protected by intellectual property
                        laws.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    6. Limitation of Liability
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        In no event shall <b>Bripplex</b> be liable for any direct, indirect,
                        incidental, special, or consequential damages arising out of or in any way
                        connected with the use of the Service or the data provided through the
                        Service.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    7. Modification of Terms
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        <b>Bripplex</b> reserves the right to modify these terms and conditions at
                        any time. Any changes will be effective immediately upon posting to the
                        website. Your continued use of the Service after the posting of changes
                        constitutes your acceptance of such changes.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    8. Governing Law
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        These terms and conditions shall be governed by and construed in accordance
                        with the laws of United States of Amera, without regard to its conflict of
                        law provisions.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    9. Contact Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        If you have any questions or concerns about these terms and conditions,
                        please contact us at {` `}
                        <MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="mailto:contact@bripplex.com"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          contact@bripplex.com.
                        </MKTypography>
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mt={4} fontWeight="regular">
                    By accepting these terms and conditions, you acknowledge that you have read,
                    understood, and agree to be bound by them.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import Navbar from "components/NavBar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import consumerRoutes from "consumerRoutes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-rental.jpeg";
import { Map } from "components/Map";
import { MapControlPanel } from "./components/MapControlPanel";
import { RequestExplanation } from "./components/RequestExplanation";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { db } from "configuration/firebase";
import { showSnackbar } from "reducer/uiReducer";
import { CircularProgress } from "@mui/material";
import { ProfileNotReady } from "./components/ProfileNotReady";
import Faq from "./components/Faq/Faq";

export const DataRequest = () => {
  const [loading, setLoading] = useState(true);
  const [profileReady, setProfileReady] = useState(false);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const getProfile = async () => {
    if (!user) {
      setLoading(false);
      return;
    }

    try {
      const snapshot = await db.collection("consumers").doc(user.uid).get();
      if (snapshot.empty) {
        setLoading(false);
        return;
      }

      const data = snapshot.data();
      if (!data.company || !data.company.approved) {
        setLoading(false);
        return;
      }
      setProfileReady(true);
      setLoading(false);
    } catch (error) {
      dispatch(
        showSnackbar({
          severity: "error",
          message: "There was a problem verifying your profile. Please refresh and try again.",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  const { markers, areas } = useSelector((state) => state.sensor);
  return (
    <>
      <Navbar
        routes={consumerRoutes}
        action={{
          type: "internal",
          route: "/consumer/buyData",
          label: "buy data",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Request data from the Bripplex community!
            </MKTypography>
            <MKTypography variant="body1" color="white" mt={1}>
              Add a region that needs data and we will notify the community to get it for you!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        {loading ? (
          <MKBox display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
          </MKBox>
        ) : (
          <>
            <RequestExplanation />
            {profileReady ? (
              <>
                <Map withDataRequest={true} markers={markers} areas={areas} />
                <MapControlPanel />
              </>
            ) : (
              <ProfileNotReady />
            )}
          </>
        )}
        <Faq />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { sensorsList } from "utils/sensors";

const initialState = {
  stats: {
    uploads: {
      ...sensorsList.reduce((a, b) => ({ ...a, [b.type]: true }), { location: 0, total: 0 }),
    },
    mobileDevices: 0,
  },
  markers: [],
  areas: [],
};

export const sensorSlice = createSlice({
  name: "sensor",
  initialState,
  reducers: {
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    addMarkers: (state, action) => {
      if (!state.markers.find((val) => val.hash === action.payload.hash))
        state.markers.push(action.payload);
    },
    setAreas: (state, action) => {
      if (state.areas.length === 0) state.areas = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStats, addMarkers, setAreas } = sensorSlice.actions;

export default sensorSlice.reducer;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import "./Map.css";
import { DeviceMarker } from "./DeviceMarker";
export const AdminMap = ({ devices }) => {
  const [googleMap, setGoogleMap] = useState();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    id: "google-map-script",
  });

  useEffect(() => {
    if (googleMap) {
      const bounds = new window.google.maps.LatLngBounds();

      devices.map((device) => {
        if (device.lastPosition) bounds.extend(device.lastPosition);
      });
      googleMap.fitBounds(bounds);
    }
  }, [googleMap]);

  return (
    <>
      {isLoaded && (
        <div style={{ height: "80vh", width: "80vw" }}>
          <GoogleMap
            mapTypeId="roadmap"
            mapContainerClassName="map-container"
            onLoad={(map) => {
              setGoogleMap(map);
            }}
          >
            {devices.map((device) => (
              <DeviceMarker device={device} key={device.id} />
            ))}
          </GoogleMap>
        </div>
      )}
    </>
  );
};

AdminMap.propTypes = {
  devices: PropTypes.array,
};

import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Grid } from "@mui/material";
import MKBox from "components/MKBox";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { db } from "configuration/firebase";
import { showSnackbar } from "reducer/uiReducer";
import { useEffect } from "react";
import { AdminConsumer } from "./AdminConsumer";

export const AdminConsumers = () => {
  const dispatch = useDispatch();
  const [emptyConsumers, setEmptyConsumers] = useState([]);
  const [approvedConsumers, setApprovedConsumers] = useState([]);
  const [pendingConsumers, setPendingConsumers] = useState([]);

  const getConsumers = async () => {
    try {
      const snapshot = await db.collection("consumers").get();

      if (snapshot.empty) return;

      for (const doc of snapshot.docs) {
        const consumer = doc.data();
        if (!consumer.company) {
          setEmptyConsumers((current) => [...current, consumer]);
        } else {
          if (consumer.company.approved) {
            setApprovedConsumers((current) => [...current, consumer]);
          } else {
            setPendingConsumers((current) => [...current, consumer]);
          }
        }
      }
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: error.message }));
    }
  };
  useEffect(() => {
    getConsumers();
  }, []);

  return (
    <MKBox mt={3}>
      <Accordion defaultExpanded mb={2}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge badgeContent={pendingConsumers.length} color="primary" showZero>
            Pending consumers
          </Badge>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            {pendingConsumers.map((consumer) => (
              <Grid item key={consumer.id}>
                <AdminConsumer consumer={consumer} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion mb={2}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge badgeContent={approvedConsumers.length} color="primary" showZero>
            Approved consumers
          </Badge>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            {approvedConsumers.map((consumer) => (
              <Grid item key={consumer.id}>
                <AdminConsumer consumer={consumer} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion mb={2}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge badgeContent={emptyConsumers.length} color="primary" showZero>
            Non ready consumers
          </Badge>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            {emptyConsumers.map((consumer) => (
              <Grid item key={consumer.id}>
                <AdminConsumer consumer={consumer} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </MKBox>
  );
};

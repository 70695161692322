// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logos/bripplex/fulllogo_transparent.png";

const date = new Date().getFullYear();

export default {
  brand: {
    // name: "Bripplex",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/bripplex",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/bripplex",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/bripplex",
    },
  ],
  menus: [
    // {
    //   name: "company",
    //   items: [{ name: "about us", href: "https://www.creative-tim.com/presentation" }],
    // },
    {
      name: "help & support",
      items: [
        { name: "What data we collect", href: "/docs/what" },
        { name: "How we collect data", href: "/docs/how" },
        { name: "How do we pay", href: "/docs/payment" },
        { name: "Support", href: "mailto:support@bripplex.com" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "/docs/appTerms" },
        { name: "data privacy", href: "/dataPrivacy" },
        { name: "licenses (EULA)", href: "/docs/EULA" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}{" "}
      <MKTypography
        component="a"
        href="https://halyardtech.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Halyard LLC
      </MKTypography>
      .
    </MKTypography>
  ),
};

import React from "react";

import { CircularProgress, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";

export const MapLoading = () => {
  return (
    <Grid
      container
      direction={"column"}
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <Grid item>
        <CircularProgress color="success" />
      </Grid>
      <Grid item>
        <MKTypography variant="body2" textAlign="center">
          Loading map...
        </MKTypography>
      </Grid>
    </Grid>
  );
};

import { createListenerMiddleware } from "@reduxjs/toolkit";
import { setAreas } from "./sensorReducer";
import { loadMarkers } from "utils/sensors";

// Create the middleware instance and methods
export const sensorsMiddleware = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
sensorsMiddleware.startListening({
  actionCreator: setAreas,
  effect: async (action, listenerApi) => {
    for (const area of action.payload) {
      await loadMarkers(area.id);
    }
  },
});

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

export const NavBarItem = ({ name, description, onClick }) => {
  return (
    <MKTypography
      key={name}
      width={"100%"}
      onClick={onClick}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      variant="button"
      textTransform="capitalize"
      minWidth={description ? "14rem" : "12rem"}
      color={description ? "dark" : "text"}
      fontWeight={description ? "bold" : "regular"}
      py={description ? 1 : 0.625}
      px={2}
      textAlign={"left"}
      sx={({ palette: { grey, dark }, borders: { borderRadius } }) => ({
        borderRadius: borderRadius.md,
        cursor: "pointer",
        transition: "all 300ms linear",

        "&:hover": {
          backgroundColor: grey[200],
          color: dark.main,

          "& *": {
            color: dark.main,
          },
        },
      })}
    >
      {description ? (
        <MKBox>
          {name}
          <MKTypography
            display="block"
            variant="button"
            color="text"
            fontWeight="regular"
            sx={{ transition: "all 300ms linear" }}
          >
            {description}
          </MKTypography>
        </MKBox>
      ) : (
        name
      )}
    </MKTypography>
  );
};

NavBarItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

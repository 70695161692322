// @mui material components
import Icon from "@mui/material/Icon";

// Sections

import { AdminOrders } from "./pages/Orders";
import { AdminMoney } from "./pages/Money";
import { AdminBlockchain } from "./pages/Blockchain";
import { AdminDevices } from "./pages/Devices";
import { AdminSensors } from "./pages/Sensors";
import { AdminConsumers } from "./pages/Consumers";

const adminRoutes = [
  {
    name: "Orders",
    key: "orders",
    private: true,
    icon: <Icon>shopping_cart</Icon>,
    route: "/admin/orders",
    component: <AdminOrders />,
  },
  {
    name: "Money",
    key: "money",
    private: true,
    icon: <Icon>attach_money</Icon>,
    route: "/admin/money",
    component: <AdminMoney />,
  },
  {
    name: "Blockchain",
    key: "blockchain",
    private: true,
    icon: <Icon>hub</Icon>,
    route: "/admin/blockchain",
    component: <AdminBlockchain />,
  },
  {
    name: "Devices",
    key: "devices",
    private: true,
    icon: <Icon>smartphone</Icon>,
    route: "/admin/devices",
    component: <AdminDevices />,
  },
  {
    name: "Sensors",
    key: "sensors",
    private: true,
    icon: <Icon>sensors</Icon>,
    route: "/admin/sensors",
    component: <AdminSensors />,
  },
  {
    name: "Consumers",
    key: "consumers",
    private: true,
    icon: <Icon>group_add</Icon>,
    route: "/admin/consumers",
    component: <AdminConsumers />,
  },
];

export default adminRoutes;

import React from "react";

import PropTypes from "prop-types";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { SensorCheck } from "components/Map/components/SensorCheck";

export const OrderStats = ({ color = "white", label, rows, files }) => {
  return (
    <MKBox display={"flex"} alignItems={"center"}>
      <SensorCheck name={label} color={color} variant="body" />
      <MKTypography variant="body2" color={color}>
        {": "}
        {rows} entries in {files} files
      </MKTypography>
    </MKBox>
  );
};

OrderStats.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  files: PropTypes.number.isRequired,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { apiRequestPaymentIntent } from "utils/api";
import { logError } from "utils/errors";
import { showBackdrop, hideBackdrop } from "reducer/uiReducer";
import { getFormatedPrice } from "utils/price";
import MKTypography from "components/MKTypography";

export const CreditCard = ({ order, selectedWallet }) => {
  const { user } = useSelector((state) => state.auth);
  const { uid } = user;
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState();
  const [message, setMessage] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const baseReturnURL = process.env.REACT_APP_RETURN_URL;

  const paymentElementOptions = {
    layout: "tabs",
  };

  const getClientSecret = async () => {
    const response = await apiRequestPaymentIntent(uid, order.id, selectedWallet);
    setClientSecret(response.clientSecret);
    return response.clientSecret;
  };

  const makePayment = async () => {
    dispatch(showBackdrop());
    try {
      const search = `?orderId=${order.id}`;

      await elements.submit();
      const secret = await getClientSecret();

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret: secret,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${baseReturnURL}${search}`,
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } catch (error) {
      logError("makePayment", error, null);
    } finally {
      dispatch(hideBackdrop());
    }
  };

  const getPaymentIntent = async () => {
    const paymentIntent = await stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case "succeeded":
        setMessage("Payment succeeded!");
        break;
      case "processing":
        setMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        setMessage("");
        break;
    }
  };

  useEffect(() => {
    if (!stripe) return;

    if (clientSecret) getPaymentIntent();
  }, [stripe, clientSecret]);
  return (
    <Grid container direction={"column"} spacing={2}>
      <Grid item>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      </Grid>
      <Grid item>
        <Button fullWidth variant="contained" color={"secondary"} onClick={makePayment}>
          <MKTypography variant={"h5"} color="white">
            Pay $ {getFormatedPrice(order.price)}
          </MKTypography>
        </Button>
        {message}
      </Grid>
    </Grid>
  );
};

CreditCard.propTypes = {
  order: PropTypes.object.isRequired,
  selectedWallet: PropTypes.string.isRequired,
};

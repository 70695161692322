/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HelpCenter page components
import FaqCollapse from "pages/Support/Faq/components/FaqCollapse";
import { ShowMeLink } from "components/ShowMeLink";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="info"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    FAQ
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: April 2024
                  </MKTypography>
                </MKBox>
                <MKBox p={6}>
                  <FaqCollapse
                    title="What type of data is collected by the app?"
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    The following data will be collected if you authorize it:
                    <br />
                    <br />
                    <ul>
                      {" "}
                      <li>
                        <b>Location</b>: Latitude and Longitude coordinates. Including altitude,
                        heading and speed.
                      </li>
                      <li>
                        <b>Microphone</b>: surrounding loudness in dB.
                      </li>
                      <li>
                        <b>Accelerometer</b>: Measures device acceleration in three-dimensional
                        space, meaning any movement or vibration
                      </li>
                      <li>
                        <b>Pedometer</b>: Counts the number of steps taken.
                      </li>
                      <li>
                        <b>Barometer</b>: Measures pressure in hectopascals (hPa)
                      </li>
                      <li>
                        <b>Device Motion</b>: Measures device motion. All data is presented in terms
                        of three axes that run through a device.{" "}
                      </li>
                      <li>
                        <b>Magnetometer</b>: Measures changes in the magnetic field specified in
                        microtesla (μT).
                      </li>
                      <li>
                        <b>Gyroscope</b>: Measures changes changes in the device rotation in
                        three-dimensional space.
                      </li>
                      <li>
                        <b>Light</b>: Measures ambient light.
                      </li>
                      {/* <li>
                        <b>Carrier</b>: Your carrier service.
                      </li> */}
                      <li>
                        <b>Compass</b>: Magnetic and true north heading.
                      </li>
                      {/* <li>
                        <b>Installed Apps</b>: List of installed applications on the device.
                      </li> */}
                    </ul>
                    <ShowMeLink href={"/docs/what"}>Read more...</ShowMeLink>
                  </FaqCollapse>
                  <FaqCollapse
                    title="How does the application works?"
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    <b>Bripplex</b> applications tracks and records <b>device sensors</b> data and
                    periodically submits them to our servers. Making it available for{" "}
                    <a href="/consumer" target="_self" rel="noopener noreferrer">
                      {" "}
                      data consumers
                    </a>
                    {` `}to preview and <b>buy</b> it if find useful.
                    <br />
                    Strick security measures are implemented to ensure no one can alter, modify or
                    even view the data your device submitted without buying it.
                    <br />
                    <br />
                    When a data consumer buys your submitted data, <b>Bripplex</b> notifies you and
                    pays you directly (<i>minus a small fee</i>) on crypto stable coin{" "}
                    <a
                      href="https://www.circle.com/en/usdc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      USDC
                    </a>
                    <br />
                    In exchange the data consumers gets a minted non-fungible token (<i>NFT</i>)
                    into their wallet that proves ownership and allows them to access the raw data
                    submitted by your device&apos;s sensors.
                    <ShowMeLink href="/docs/how">Want to learn more?</ShowMeLink>
                  </FaqCollapse>
                  <FaqCollapse
                    title="Is personal information stored? Is there a way someone can identify me with the submited data?"
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    No personal information of any kind is requested from you or stored, ensuring{" "}
                    <b>100% anonymity</b>. The data your device submits is not related to you as an
                    individual, but more related to the environment and location your device
                    currently is.
                    <br />
                    <br />
                    To pay you whenever a data consumers purchases your submitted data, we send the
                    money on blockchain to an account abstraction wallet that is automatically
                    created by the application upon installation.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Can I view the type of data being submited?"
                    open={collapse === 4}
                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                  >
                    <b>Of course! it is your data!</b>
                    <br />
                    <br />
                    You can view real time the raw data your device is recording. In addition you
                    can review statistics on how much data the apps submits to our servers.
                    <br />
                    You decide what data to enable and when to start recording it. Of course, the
                    more data you submit, bigger the chances data consumers will find it useful
                    enough to buy it.
                  </FaqCollapse>
                  <FaqCollapse
                    title="How do I get paid for the data I'm sharing?"
                    open={collapse === 5}
                    onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                  >
                    The <b>Bripplex</b> app takes care of everything. You only need to decide which
                    data to collect and share and when to track it.
                    <br />
                    <br />
                    Whenever a data consumers picks part of your submitted data to buy it, we notify
                    you and send you automatically the money to your device.
                    <ShowMeLink href="/docs/how">Read more about payment</ShowMeLink>
                  </FaqCollapse>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Faq;

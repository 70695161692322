/* eslint-disable no-param-reassign */
/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import consumerRoutes from "consumerRoutes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg3.jpg";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

import { MyProfile } from "./components/MyProfile";
import Navbar from "components/NavBar";
import { MyOrders } from "./components/MyOrders";
import { useParams } from "react-router-dom";
import { MyFiles } from "./components/MyFiles";
import { useSelector } from "react-redux";
import { getOrders } from "utils/consumers";

export const MyAccount = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { user } = useSelector((state) => state.auth);

  const { tab } = useParams();
  const handleTabType = (event, newValue) => setActiveTab(newValue);

  useEffect(() => {
    if (tab === "myOrders") {
      setActiveTab(1);
    }
  }, [tab]);

  useEffect(() => {
    getOrders(user.uid);
  }, []);

  return (
    <>
      <Navbar
        routes={consumerRoutes}
        action={{
          type: "internal",
          route: "/consumer/buyData",
          label: "buy data",
          color: "info",
        }}
        transparent
        light
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        <Grid
          container
          justifyContent="center"
          xs={12}
          lg={8}
          paddingY={5}
          mx="auto"
          direction={"column"}
        >
          <Grid item>
            <AppBar position="static">
              <Tabs value={activeTab} onChange={handleTabType}>
                <Tab
                  label="My Profile"
                  icon={<Icon>person</Icon>}
                  color="primary"
                  sx={{ marginX: { xs: 0, md: 3, lg: 5 } }}
                />
                <Tab
                  label="My Orders"
                  icon={<Icon>add_location</Icon>}
                  sx={{ marginX: { xs: 0, md: 3, lg: 5 } }}
                />
                <Tab
                  label="My Files"
                  icon={<Icon>article</Icon>}
                  sx={{ marginX: { xs: 0, md: 3, lg: 5 } }}
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {activeTab === 0 && <MyProfile />}
        {activeTab === 1 && <MyOrders />}
        {activeTab === 2 && <MyFiles />}
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </Card>
    </>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { InfoWindow } from "@react-google-maps/api";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { shortenAddress } from "utils/web3";
import moment from "moment";

export const DeviceInfoWindow = ({ device }) => {
  return (
    <InfoWindow position={device.lastPosition}>
      <MKBox margin={1}>
        <MKTypography variant={"h5"}>{shortenAddress(device.id)}</MKTypography>
        <MKTypography variant={"body2"}>
          Last Login {moment(device.lastLogin?.toDate())?.fromNow()}
        </MKTypography>
        {device.uploads && (
          <>
            {Object.keys(device.uploads).map((key, index) => (
              <MKTypography variant="body2" key={index}>
                {key}: {device.uploads[key]}
              </MKTypography>
            ))}
          </>
        )}
      </MKBox>
    </InfoWindow>
  );
};

DeviceInfoWindow.propTypes = {
  device: PropTypes.object.isRequired,
};

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Rental page components
import FaqCollapse from "pages/LandingPages/Rental/components/FaqCollapse";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="What are Data Requests?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              <b>Data Request</b> is a tool to let the <b>Bripplex</b> community know that a
              consumer has interest in getting data from an specific area. The consumer specifies
              which area and sensors are requested and near <b>Bripplex</b> apps in the area are
              notified. <br />
              <br />
              The consumer has no obligation on buying submitted data but is a clear interest on
              buying it!
            </FaqCollapse>
            <FaqCollapse
              title="How is the community notified?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              Any <b>Bripplex</b> app near the area defined by the data request, gets a notification
              with the sensors requested and the intended usage of the data by the consumer. We
              notify each device only once.
              <br />
              <br />
              Neither the data consumer or the <b>Bripplex</b> app user has obligation to buy or
              submit the data.
            </FaqCollapse>
            <FaqCollapse
              title="How long does the data request remains active?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              We will notify all the <b>Bripplex</b> devices near the area for a period of a month.
              After that no new notifications will be sent. Each device will be notified only once.
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;

import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Icon from "@mui/material/Icon";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { db } from "configuration/firebase";
import moment from "moment";
import { showSnackbar } from "reducer/uiReducer";
import { showBackdrop } from "reducer/uiReducer";
import { hideBackdrop } from "reducer/uiReducer";
import { logError } from "utils/errors";
import { useNavigate } from "react-router-dom";
import { OrderStats } from "../OrderStats";
import { sensorsList } from "utils/sensors";
import { isMobile } from "react-device-detect";

export const ReviewDataModal = ({ show, onClose, polygon }) => {
  const { markers } = useSelector((state) => state.sensor);
  const { user } = useSelector((state) => state.auth);
  const { orderIncludedSensors } = useSelector((state) => state.consumer);
  const [totals, setTotals] = useState({ rows: 0, files: 0 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const statsIntialState = sensorsList.reduce(
    (stats, sensor) => ({
      ...stats,
      [sensor.type]: { files: 0, rows: 0 },
    }),
    { location: { files: 0, rows: 0 } }
  );
  const [stats, setStats] = useState(statsIntialState);
  const [includedMarkers, setIncludedMarkers] = useState([]);

  const closeModal = () => {
    setStats(statsIntialState);
    onClose();
  };

  useEffect(() => {
    calculateTotals();
  }, [stats]);

  const calculateTotals = () => {
    const totalFiles = sensorsList.reduce((total, sensor) => {
      // the sensor is included
      if (orderIncludedSensors[sensor.type]) {
        if (stats[sensor.type]?.files) {
          // if we have stats for this sensor, we add them
          total = total + stats[sensor.type]?.files;
        }
      }
      // return total + orderIncludedSensors[sensor.type] ? stats[sensor.type]?.files || 0 : 0;
      return total;
    }, stats.location.files);

    const totalRows = sensorsList.reduce((total, sensor) => {
      // the sensor is included
      if (orderIncludedSensors[sensor.type]) {
        if (stats[sensor.type]?.rows) {
          // if we have stats for this sensor, we add them
          total = total + stats[sensor.type]?.rows;
        }
      }
      // return total + orderIncludedSensors[sensor.type] ? stats[sensor.type]?.files || 0 : 0;
      return total;
    }, stats.location.rows);

    setTotals({ files: totalFiles, rows: totalRows });
  };

  useEffect(() => {
    if (!show) setIncludedMarkers([]);

    console.log(stats);
  }, [show]);

  const calculateData = () => {
    markers.map((marker) => {
      const pos = { lat: marker.firstLat, lng: marker.firstLng };
      const contains = window.google.maps.geometry.poly.containsLocation(pos, polygon);
      if (contains) {
        setIncludedMarkers((current) => [...current, marker]);
        setStats((current) => ({
          location: {
            files: current.location.files + 1,
            rows: current.location.rows + marker.rows,
          },
          magnetometer: {
            files: current.magnetometer.files + (marker.magnetometer?.files || 0),
            rows: current.magnetometer.rows + (marker.magnetometer?.rows || 0),
          },
          pedometer: {
            files: current.pedometer.files + (marker.pedometer?.files || 0),
            rows: current.pedometer.rows + (marker.pedometer?.rows || 0),
          },
          accelerometer: {
            files: current.accelerometer.files + (marker.accelerometer?.files || 0),
            rows: current.accelerometer.rows + (marker.accelerometer?.rows || 0),
          },
          light: {
            files: current.light.files + (marker.light?.files || 0),
            rows: current.light.rows + (marker.light?.rows || 0),
          },
          microphone: {
            files: current.microphone.files + (marker.microphone?.files || 0),
            rows: current.microphone.rows + (marker.microphone?.rows || 0),
          },
          deviceMotion: {
            files: current.deviceMotion.files + (marker.deviceMotion?.files || 0),
            rows: current.deviceMotion.rows + (marker.deviceMotion?.rows || 0),
          },
          gyroscope: {
            files: current.gyroscope.files + (marker.gyroscope?.files || 0),
            rows: current.gyroscope.rows + (marker.gyroscope?.rows || 0),
          },
          barometer: {
            files: current.barometer.files + (marker.barometer?.files || 0),
            rows: current.barometer.rows + (marker.barometer?.rows || 0),
          },
          carrier: {
            files: current.carrier.files + (marker.carrier?.files || 0),
            rows: current.carrier.rows + (marker.carrier?.rows || 0),
          },
          compass: {
            files: current.compass.files + (marker.compass?.files || 0),
            rows: current.compass.rows + (marker.compass?.rows || 0),
          },
          installedApps: {
            files: current.installedApps.files + (marker.installedApps?.files || 0),
            rows: current.installedApps.rows + (marker.installedApps?.rows || 0),
          },
        }));
      }
    });
  };

  useEffect(() => {
    if (polygon) calculateData();
  }, [polygon]);

  const handleCreateOrder = async () => {
    dispatch(showBackdrop());
    const { uid } = user;
    const order = {
      markers: includedMarkers.map((val) => ({ hash: val.hash, areaId: val.areaId })),
      ...stats,
      includedSensors: orderIncludedSensors,
      creationTimestamp: moment().unix(),
      status: 0,
      price: 0.0,
    };

    // order status are:
    // 0 just created and price is to be defined
    // 1 price has been defined, so the user can do checkout
    // 2 order has been paid
    try {
      const ref = db.collection("consumers").doc(uid).collection("orders").doc();
      order.id = ref.id;
      await ref.set(order);
      navigate("/consumer/myAccount/myOrders");
    } catch (error) {
      dispatch(
        showSnackbar({
          severity: "error",
          message:
            "There was an error preparing your order. Please check your network connection, refresh and try again.",
        })
      );
      logError("CreateOrder", error);
    } finally {
      dispatch(hideBackdrop());
    }
  };

  return (
    <Modal open={show} onClose={closeModal} sx={{ display: "grid", placeItems: "center" }}>
      <Slide direction="down" in={show} timeout={500}>
        <MKBox
          position="relative"
          width={isMobile ? "100%" : "500px"}
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          variant="gradient"
          bgColor="error"
          shadow="sm"
        >
          {includedMarkers.length === 0 ? (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={2}
              padding={2}
            >
              <Grid item xs={12} mt={3}>
                <MKTypography variant="h4" color="white">
                  Your selection doesn&apos;t include any data.
                </MKTypography>
              </Grid>
              <Grid item xs={12}>
                <MKTypography variant="body" color="white">
                  Make sure to draw over area that includes sensor data. Please try again.
                </MKTypography>
              </Grid>
              <Grid item xs={12} mb={3}>
                <MKButton variant="text" color="white" onClick={closeModal}>
                  Close
                </MKButton>
              </Grid>
            </Grid>
          ) : (
            <>
              <MKBox
                display="flex"
                alginItems="center"
                justifyContent="space-between"
                py={3}
                px={1}
              >
                <MKTypography variant="h6" color="white">
                  Order Creation
                </MKTypography>
                <CloseIcon
                  color="white"
                  fontSize="medium"
                  sx={{ cursor: "pointer" }}
                  onClick={closeModal}
                />
              </MKBox>
              <Divider light sx={{ my: 0 }} />
              <MKBox p={0} textAlign="center" color="white">
                <Icon fontSize="large" color="inherit">
                  notifications_active
                </Icon>
                <MKTypography variant="body" color="white" mt={3} mb={3}>
                  Your selection includes:
                </MKTypography>
                <MKTypography variant={isMobile ? "h5" : "h4"} color="white" opacity={0.8} mb={2}>
                  {totals.rows} entries in {totals.files} files.
                </MKTypography>
                <MKTypography variant={isMobile ? "body" : "h5"} color="white" opacity={0.8} mb={2}>
                  with the following data:
                </MKTypography>
                <MKBox
                  dislay={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ marginLeft: 1 }}
                >
                  <OrderStats
                    label="Location"
                    rows={stats.location.rows}
                    files={stats.location.files}
                  />
                  {sensorsList.map((sensor) => {
                    return (
                      orderIncludedSensors[sensor.type] &&
                      stats[sensor.type]?.rows > 0 && (
                        <OrderStats
                          label={sensor.label}
                          rows={stats[sensor.type].rows}
                          files={stats[sensor.type].files}
                          key={sensor.type}
                        />
                      )
                    );
                  })}
                </MKBox>
                <MKTypography variant="h5" color="white" opacity={0.8} mb={2} mt={3}>
                  Do you want to create a buy order?
                </MKTypography>
              </MKBox>
              <Divider light sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" py={2} px={1.5}>
                <MKButton variant="text" color="white" onClick={closeModal}>
                  No
                </MKButton>
                <MKButton color="white" onClick={handleCreateOrder}>
                  Yes
                </MKButton>
              </MKBox>
            </>
          )}
        </MKBox>
      </Slide>
    </Modal>
  );
};

ReviewDataModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  polygon: PropTypes.object.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { Card, Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { CopyButton } from "components/CopyButton";

import { CancelOrderButton } from "./components/CancelOrderButton";
import { OrderStatus } from "./components/OrderStatus";
import { OrderPrice } from "./components/OrderPrice";
import { IncludedList } from "./components/IncludedList";
import { OrderHeader } from "./components/OrderHeader";
import { isMobile } from "react-device-detect";
import { shortenAddress } from "utils/web3";

export const Order = ({ order }) => {
  return (
    <Card sx={{ width: "100%", marginY: 2 }}>
      <Grid container alignItems="center">
        <Grid
          container
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          margin={3}
        >
          <Grid item xs={12} md={10}>
            <MKTypography variant="body2">
              Order Id <b>{isMobile ? shortenAddress(order.id) : order.id}</b>{" "}
              <CopyButton text={order.id} />
            </MKTypography>
            <MKBox mt={1}>
              <OrderStatus status={order.status || 0} />{" "}
            </MKBox>
          </Grid>
          {order.status < 2 && (
            <Grid item xs={12} md={2} textAlign={"center"} mt={2}>
              <CancelOrderButton id={order.id} />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} lg={8}>
          <MKBox py={3} px={4}>
            <OrderHeader order={order} />
            <Grid container item xs={12} lg={3} sx={{ mt: 3, mb: 1 }}>
              <MKTypography variant="h6">Data included:</MKTypography>
            </Grid>
            <IncludedList order={order} showJSON={true} />
          </MKBox>
        </Grid>
        <OrderPrice order={order} />
      </Grid>
    </Card>
  );
};

Order.propTypes = {
  order: PropTypes.object.isRequired,
};

import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MKButton from "components/MKButton";

import { useNavigate } from "react-router-dom";
import MKTypography from "components/MKTypography";

export const BackButton = () => {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (navigate.length === 0) setIsDisabled(true);
    else setIsDisabled(false);
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <MKButton
      onClick={handleGoBack}
      disabled={isDisabled}
      variant={"contained"}
      size={"medium"}
      startIcon={<ArrowBackIcon />}
    >
      <MKTypography>Go Back</MKTypography>
    </MKButton>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { Order } from "pages/Consumer/pages/MyAccount/components/MyOrders/components/Order";
import { Grid, Link } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";
import MKButton from "components/MKButton";
import moment from "moment";
import { OrderStatus } from "./OrderStatus";
import { OrderPrice } from "./OrderPrice";

export const AdminOrder = ({ order }) => {
  return (
    <Grid container spacing={2} bgcolor={"#cdf1e9"} padding={2}>
      <Grid item>
        <MKTypography variant="body">
          <MKBadge badgeContent="UID" variant="contained" color="info" container /> {order.uid}{" "}
        </MKTypography>
        <MKTypography variant="body2">
          <MKBadge badgeContent="Email" variant="contained" color="info" container />{" "}
          <Link href={`mailto:${order.company.email}`}>{order.company.email}</Link>
        </MKTypography>
        <MKBadge badgeContent="Timestamp" variant="contained" color="info" container />{" "}
        {moment.unix(order.creationTimestamp).fromNow()}
      </Grid>
      <Grid item>
        <Order order={order} />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction={"row"}
          justifyContent="space-around"
          alignItems={"center"}
          padding={2}
        >
          <Grid item xs={12} md={5}>
            <OrderPrice order={order} />
          </Grid>
          <Grid item xs={12} md={5}>
            <OrderStatus order={order} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

AdminOrder.propTypes = {
  order: PropTypes.object.isRequired,
};

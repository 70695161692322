import { CircularProgress, Container, Grid } from "@mui/material";
import { db } from "configuration/firebase";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";
import { AdminMap } from "./components/AdminMap";
import MKTypography from "components/MKTypography";
import { shortenAddress } from "utils/web3";
import moment from "moment";

export const AdminDevices = () => {
  const [adminDevices, setAdminDevices] = useState([]);
  const dispatch = useDispatch();

  const loadAdminDevices = async () => {
    try {
      const snapshot = await db.collection("devices").get();
      const result = [];
      for await (const doc of snapshot.docs) {
        const device = doc.data();
        result.push({ ...device, id: doc.id });
      }
      setAdminDevices(result);
    } catch (error) {
      dispatch(
        showSnackbar({
          severity: "error",
          message: "There was an error loading devices stats. Refresh and try again.",
        })
      );
      console.log(error);
    }
  };

  const last5Registered = () => {
    const mySort = (a, b) => {
      if (a.registrationTimestamp > b.registrationTimestamp) return -1;
      else return 1;
    };
    const sorted = adminDevices.sort(mySort);
    return sorted.slice(0, 4);
  };

  const last5Logged = () => {
    const mySort = (a, b) => {
      if (moment(a.lastLogin?.toDate()).unix() > moment(b.lastLogin?.toDate()).unix()) return -1;
      else return 1;
    };
    const sorted = adminDevices.filter((val) => val.lastLogin).sort(mySort);
    return sorted.slice(0, 4);
  };

  useEffect(() => {
    loadAdminDevices();
  }, []);

  return (
    <>
      {adminDevices.length === 0 ? (
        <CircularProgress />
      ) : (
        <Container>
          <MKTypography my={4} variant={"h4"} textAlign="center">
            Total Devices: {adminDevices.length}
          </MKTypography>
          <Grid
            container
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            width={"100%"}
          >
            <Grid item xs={12} lg={6}>
              <MKTypography variant={"h5"}>Last 5 registered:</MKTypography>
              {last5Registered().map((val) => (
                <MKTypography variant={"body2"} key={val.id}>
                  {shortenAddress(val.id)}: {moment.unix(val.registrationTimestamp).fromNow()};
                </MKTypography>
              ))}
            </Grid>
            <Grid item xs={12} lg={6}>
              <MKTypography variant={"h5"}>Last 5 logged:</MKTypography>
              {last5Logged().map((val) => (
                <MKTypography variant={"body2"} key={val.id}>
                  {shortenAddress(val.id)}: {moment(val.lastLogin?.toDate()).fromNow()}
                </MKTypography>
              ))}
            </Grid>
          </Grid>
          <AdminMap devices={adminDevices} />
        </Container>
      )}
    </>
  );
};

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Blogs/SingleArticle/sections/Information";

// Routes
import consumerRoutes from "consumerRoutes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg5.jpg";
import Navbar from "components/NavBar";
import MKButton from "components/MKButton";
import { Map } from "components/Map";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { db } from "configuration/firebase";
import { showSnackbar } from "reducer/uiReducer";
import { ProfileNotReady } from "../DataRequest/components/ProfileNotReady";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { BuyHeader } from "./BuyHeader";
import Faq from "./components/Faq/Faq";
import { OrderIncludedSensors } from "./components/OrderIncludedSensors";

export const BuyData = () => {
  const headerRef = useRef(null);
  const { markers, areas } = useSelector((state) => state.sensor);
  const [loading, setLoading] = useState(true);
  const [profileReady, setProfileReady] = useState(false);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const getProfile = async () => {
    if (!user) {
      setLoading(false);
      return;
    }

    try {
      const snapshot = await db.collection("consumers").doc(user.uid).get();
      if (snapshot.empty) {
        setLoading(false);
        return;
      }

      const data = snapshot.data();
      if (!data.company || !data.company.approved || !data.wallets || data.wallets.length === 0) {
        setLoading(false);
        return;
      }
      setProfileReady(true);
      setLoading(false);
    } catch (error) {
      dispatch(
        showSnackbar({
          severity: "error",
          message: "There was a problem verifying your profile. Please refresh and try again.",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  return (
    <>
      <MKBox bgColor="white" py={0.25}>
        <Navbar
          routes={consumerRoutes}
          action={{
            type: "internal",
            route: "/consumer/buyData",
            label: "buy data",
            color: "info",
          }}
          transparent
          light
        />
      </MKBox>
      <MKBox
        ref={headerRef}
        minHeight="85vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Buy data directly from Bripplex community
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
              We use blockchain technology to make it secure and fast!
            </MKTypography>
            <MKBox>
              <MKButton variant="text" color="white" component={Link} to="/docs/how">
                read how
              </MKButton>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {loading ? (
          <MKBox display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
          </MKBox>
        ) : (
          <>
            <BuyHeader />
            {profileReady ? (
              <>
                <OrderIncludedSensors />
                <Map withDataRequest={false} withBuyData={true} markers={markers} areas={areas} />
              </>
            ) : (
              <ProfileNotReady />
            )}
          </>
        )}
        <Faq />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

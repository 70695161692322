import React from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";
import MKBadge from "components/MKBadge";
import { isMobile } from "react-device-detect";

export const DownloadApp = () => {
  return (
    <MKBox component="section" mt={6} py={{ xs: 0, lg: 6 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={4} mb={{ xs: 3, lg: 0 }}>
            <MKTypography variant="h3" mb={0.5} textAlign={{ lg: "right", xs: "center" }}>
              Download the app
            </MKTypography>
            <MKTypography variant="body1" color="text" textAlign={{ lg: "right", xs: "center" }}>
              Start earning money!
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={8} marginBottom={{ xs: 5 }}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={1.5}
              flexWrap="wrap"
              justifyContent={{ xs: "flex-start", lg: "center" }}
            >
              <MKButton
                color="primary"
                component={Link}
                fullWidth={isMobile}
                to={
                  "https://appdistribution.firebase.google.com/testerapps/1:694983192742:ios:b2a97577aec65ebd640467/releases/3ulkv0e2p9pgo?utm_source=firebase-console"
                }
                target="_blank"
                rel={"noopener"}
              >
                <i className="fab fa-apple" />
                &nbsp; Download for IOS
              </MKButton>
              <MKBadge
                badgeContent={
                  <>
                    available
                    <br />
                    soon!
                  </>
                }
                overlap="rectangular"
                color="primary"
              >
                <MKButton color="secondary" disabled fullWidth={isMobile ? true : false}>
                  <i className="fab fa-android" />
                  &nbsp; Download for Android
                </MKButton>
              </MKBadge>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import { apiGetFiles } from "utils/api";
import { requestAuthMessage } from "utils/api";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";
import { OrderHeader } from "../../../MyOrders/components/Order/components/OrderHeader";
import { shortenAddress } from "utils/misc";
import { Link } from "react-router-dom";
import { Map } from "components/Map";
import { db } from "configuration/firebase";
import { IncludedList } from "../../../MyOrders/components/Order/components/IncludedList";

import { useSDK } from "@metamask/sdk-react";
import { signMessage } from "utils/web3";
import { isMobile } from "react-device-detect";

export const NFTContainer = ({ nft, showDownload = true, showOpensea = true }) => {
  const [markers, setMarkers] = useState([]);
  const { sdk, connected, connecting, provider, chainId } = useSDK();
  const loadNFTMarkers = async () => {
    const snapshot = await db
      .collection("nft")
      .doc(nft.tokenId.toString())
      .collection("location")
      .get();
    for (const doc of snapshot.docs) {
      setMarkers((current) => [...current, doc.data()]);
    }
  };
  useEffect(() => {
    loadNFTMarkers();
  }, []);

  const network = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleDownload = async () => {
    let response = await requestAuthMessage(nft.owner);
    if (!response.ok) {
      dispatch(
        showSnackbar({
          severity: "error",
          message: `Unable to download file. ${response.data}`,
        })
      );
      return;
    }
    const message = response.data;
    // sign it
    const accounts = await sdk?.connect();
    if (accounts.length === 0) return;

    const signature = await signMessage(accounts[0], provider, message);

    try {
      response = await apiGetFiles(nft.tokenId, user.uid, nft.owner, signature);
      if (!response.ok) {
        dispatch(
          showSnackbar({
            severity: "error",
            message: `Unable to download file. ${response.data}`,
          })
        );
        return;
      }

      const downloadURL = response.data;
      window.location.href = downloadURL;
    } catch (error) {
      dispatch(
        showSnackbar({
          severity: "error",
          message: `We have been unable to download your file. Please verify your connection and try again.`,
        })
      );
    }
  };

  return (
    <MKBox component="section" position="relative" py={6}>
      {markers.length > 0 && (
        <Grid container item xs={12} sx={{ mx: "auto" }}>
          <MKBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" py={6}>
            <Grid container direction={"row"}>
              <Grid item xs={12} md={2} xl={6} sx={{ px: 1 }}>
                <Map
                  width={"100%"}
                  height={isMobile ? "400px" : "600px"}
                  withDataRequest={false}
                  withBuyData={false}
                  markers={markers}
                  areas={[]}
                  mapTypeControl={false}
                />
              </Grid>
              <Grid item xs={12} xl={6}>
                <Grid container direction={"column"}>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    xl={4}
                    my={2}
                    sx={{
                      // position: "relative",
                      px: { xs: 6, md: 2 },
                      mt: { xs: 3, md: 0 },
                      my: { xs: 0, md: "auto" },
                      color: ({ palette: { white } }) => white.main,
                    }}
                  >
                    Token ID {nft.tokenId}
                    <OrderHeader order={nft} />
                    <Grid container direction={"row"} justifyContent="space-around">
                      <Grid item xs={12}>
                        <MKTypography variant="button" fontWeight="bold" color="white">
                          tx Hash -{" "}
                          <MKTypography
                            variant="button"
                            fontWeight="bold"
                            color="white"
                            component={Link}
                            to={
                              network === "polygon-mumbai"
                                ? `https://mumbai.polygonscan.com/tx/${nft.txHash}`
                                : `https://polygonscan.com/tx/${nft.txHash}`
                            }
                            rel={"noopener noreferrer"}
                            target="_black"
                          >
                            {shortenAddress(nft.txHash)}{" "}
                            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                          </MKTypography>
                        </MKTypography>
                      </Grid>
                      {showOpensea && (
                        <Grid item xs={12}>
                          <Link
                            to={process.env.REACT_APP_BLOCKCHAIN_OPENSEA_LINK + nft.tokenId}
                            rel={"noopener noreferrer"}
                            target="_black"
                          >
                            <MKTypography
                              variant="button"
                              fontWeight="bold"
                              color="white"
                              sx={{ marginY: 2 }}
                            >
                              Buy / sell at Opensea{" "}
                              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                            </MKTypography>
                          </Link>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item margin={1} marginTop={3}>
                    <IncludedList order={nft} isNFT={true} />
                  </Grid>
                  {showDownload && (
                    <Grid
                      item
                      xs={12}
                      margin={1}
                      sx={{ px: { xs: 0, xl: 0 }, my: { xs: 2, xl: "auto" }, lineHeight: 1 }}
                    >
                      <MKButton
                        onClick={handleDownload}
                        variant="contained"
                        fullWidth
                        disabled={nft.status !== 1} // status 0 file is not ready, status 1 is ready to download
                      >
                        <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                        Download files
                      </MKButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      )}
    </MKBox>
  );
};

NFTContainer.propTypes = {
  nft: PropTypes.string.isRequired,
  showDownload: PropTypes.bool,
  showOpensea: PropTypes.bool,
};

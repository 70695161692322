import { useEffect, useState } from "react";
import Navbar from "components/NavBar";

import adminRoutes from "./AdminRoutes";
import { useParams } from "react-router-dom";
import { AdminBlockchain } from "./pages/Blockchain";
import { AdminMoney } from "./pages/Money";
import { AdminDevices } from "./pages/Devices";
import { AdminOrders } from "./pages/Orders";
import { AdminSensors } from "./pages/Sensors";
import { AdminConsumers } from "./pages/Consumers";
import MKBox from "components/MKBox";
import { IsAdminValidator } from "./IsAdminValidator";

export const Admin = () => {
  const { section } = useParams();
  const [component, setComponent] = useState();

  useEffect(() => {
    switch (section) {
      case "blockchain":
        setComponent(<AdminBlockchain />);
        break;
      case "money":
        setComponent(<AdminMoney />);
        break;
      case "devices":
        setComponent(<AdminDevices />);
        break;
      case "orders":
        setComponent(<AdminOrders />);
        break;
      case "sensors":
        setComponent(<AdminSensors />);
        break;
      case "consumers":
        setComponent(<AdminConsumers />);
        break;

      default:
        setComponent(<AdminOrders />);
        break;
    }
  }, [section]);

  return (
    <>
      <Navbar routes={adminRoutes} sticky />
      <MKBox
        minHeight="40vh"
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <IsAdminValidator section={section}>{component}</IsAdminValidator>
      </MKBox>
    </>
  );
};

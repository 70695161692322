import React, { useState } from "react";
import PropTypes from "prop-types";

import { Circle } from "@react-google-maps/api";
import { AreaInfoWindows } from "../AreaInfoWindows/AreaInfoWindows";

export const AreaCircle = ({ area, onZoomHandler, radius }) => {
  const [showAreaInfoWindow, setShowAreaInfoWindow] = useState(false);

  return (
    <>
      <Circle
        center={area.center}
        radius={radius ?? area.distance / 2}
        options={{
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        }}
        onClick={() => setShowAreaInfoWindow(true)}
      />
      {showAreaInfoWindow && (
        <AreaInfoWindows
          area={area}
          onZoomHandler={onZoomHandler}
          onClose={() => setShowAreaInfoWindow(false)}
        />
      )}
    </>
  );
};

AreaCircle.propTypes = {
  area: PropTypes.object.isRequired,
  onZoomHandler: PropTypes.func.isRequired,
  radius: PropTypes.number,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Marker } from "@react-google-maps/api";
import { DeviceInfoWindow } from "./DeviceInfoWindow";

export const DeviceMarker = ({ device }) => {
  const [show, setShow] = useState(false);
  const getIconColor = (uploads = []) => {
    switch (uploads.length) {
      case 0:
        return "https://maps.google.com/mapfiles/ms/icons/red-dot.png";
      case 1:
        return "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
      case 2:
        return "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
      case 3:
        return "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";
      case 4:
        return "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";
      case 5:
        return "https://maps.google.com/mapfiles/ms/icons/green-dot.png";
      default:
        return "https://maps.google.com/mapfiles/ms/icons/green-dot.png";
    }
  };
  return (
    <>
      <Marker
        position={device.lastPosition}
        onClick={() => setShow(true)}
        icon={getIconColor(device.uploads)}
      >
        {show && <DeviceInfoWindow device={device} setShow={setShow} />}
      </Marker>
    </>
  );
};

DeviceMarker.propTypes = {
  device: PropTypes.object.isRequired,
};

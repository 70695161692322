import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { db } from "configuration/firebase";
import { showSnackbar } from "reducer/uiReducer";
import { saveRequests } from "reducer/consumerReducer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { AreYouSureModal } from "components/AreYouSureModal";
import { sensorsList } from "utils/sensors";

export const MapControlPanel = () => {
  const { dataRequests } = useSelector((state) => state.consumer);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [sensors, setSensors] = useState({
    ...sensorsList.reduce((a, b) => ({ ...a, [b.type]: true }), { location: true }),
  });
  const { user } = useSelector((state) => state.auth);
  const { uid } = user || {};
  const dispatch = useDispatch();

  const handleSave = async () => {
    try {
      for (const request of dataRequests.requests) {
        const ref = db.collection("consumers").doc(uid).collection("dataRequest").doc(request.id);
        if (request.delete) {
          await ref.delete();
        } else {
          await ref.set({ ...request, ...sensors });
        }
      }

      dispatch(saveRequests());

      dispatch(
        showSnackbar({
          severity: "success",
          message: "Data requests saved successfully",
        })
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          severity: "error",
          message:
            "We have been unable to save your data request. Please verify your connection and try again.",
        })
      );
    }
  };
  return (
    <MKBox component="section" py={{ xs: 2 }}>
      <Container>
        <Grid container item xs={12}>
          <Card sx={{ width: "100%" }}>
            <Grid container alignItems="center">
              <Grid item xs={12} lg={10}>
                <MKBox py={3} px={4}>
                  <Grid container item xs={12} lg={3} sx={{ mt: 2, mb: 1 }}>
                    <MKTypography variant="h6">Sensors I need:</MKTypography>
                  </Grid>
                  <FormGroup>
                    <Grid container direction={"row"} spacing={1}>
                      {[{ type: "location", label: "Location" }, ...sensorsList].map((val) => (
                        <Grid item xs={12} md={4} lg={3} key={val.type}>
                          <MKTypography variant="body1" color="dark">
                            <FormControlLabel
                              value={sensors[val.type]}
                              disabled={val.type === "location" || !dataRequests.withUpdates}
                              control={<Checkbox defaultChecked />}
                              label={val.label}
                              onChange={() =>
                                setSensors((current) => ({
                                  ...current,
                                  [val.type]: !sensors[val.type],
                                }))
                              }
                            />
                          </MKTypography>
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={2}>
                <MKBox p={3} textAlign="center">
                  <MKButton
                    variant="gradient"
                    color="error"
                    size="large"
                    disabled={!dataRequests.withUpdates}
                    sx={{ my: 2 }}
                    onClick={() => setShowConfirmation(true)}
                  >
                    {dataRequests.withUpdates ? <Icon>pending</Icon> : <Icon>save</Icon>}
                    {` `}Save{!dataRequests.withUpdates && "d"}
                  </MKButton>
                </MKBox>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Container>
      <AreYouSureModal
        show={showConfirmation}
        setShow={setShowConfirmation}
        title={"Save Changes?"}
        message={<>Are you sure you want to save your changes?</>}
        onAccept={handleSave}
        okButtonMessage="Save!"
      />
    </MKBox>
  );
};

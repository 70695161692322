import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import MKBox from "components/MKBox";

export const AuthWrapper = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { uid } = user || {};

  if (user === undefined)
    return (
      <MKBox display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </MKBox>
    );

  return uid ? <Outlet /> : <Navigate to="/signin" replace state={{ from: location }} />;
};

import { useEffect, useRef, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import { useSelector } from "react-redux";
import { StaticCounter } from "../StaticCounter/StaticCounter";
import { sensorsList } from "utils/sensors";
import { ShowMeLink } from "components/ShowMeLink";

export const DataStats = () => {
  const { stats } = useSelector((state) => state.sensor);
  const total = useRef(0);

  useEffect(() => {
    if (stats.uploads.total > 0 && total.current == 0) {
      total.current = stats.uploads.total;
    }
  }, [stats]);

  const [staticCounter, setStaticCounter] = useState(false);

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            {staticCounter ? (
              <StaticCounter
                color="primary"
                count={stats.uploads.total.toLocaleString()}
                title="Entries submitted"
                description="of data including location, pressure, altitude and many more"
              />
            ) : (
              <DefaultCounterCard
                color="primary"
                onEnd={() => setStaticCounter(true)}
                count={total.current}
                title="Entries submitted"
                description="of data including location, pressure, altitude and many more"
              />
            )}
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <DefaultCounterCard
              color="primary"
              count={stats.mobileDevices}
              title="Registered devices"
              description="Recording and submitting data from everywhere"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              color="primary"
              count={sensorsList.length + 1}
              title="Sensors"
              description={
                <>
                  To generate more value for you!
                  <MKBox
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                  >
                    <ShowMeLink href="/docs/what" style={{ textAlign: "center" }}>
                      New ones to be added soon!
                    </ShowMeLink>
                  </MKBox>
                </>
              }
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

import { Grid } from "@mui/material";
import { CopyButton } from "components/CopyButton";
import MKTypography from "components/MKTypography";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";
import { myReadOnlyWeb3, toETH } from "utils/web3";

export const USDCPayerBalance = () => {
  const [maticBalance, setMaticBalance] = useState(0);
  const [usdcBalance, setUSDCBalance] = useState(0);
  const dispatch = useDispatch();
  const web3 = myReadOnlyWeb3();

  const calculateMaticBalance = async () => {
    try {
      const result = await web3.eth.getBalance(process.env.REACT_APP_USDC_PAYER, "latest");
      setMaticBalance(toETH(result));
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: error.message }));
    }
  };

  const calculateUSDCBalance = async () => {
    const abi = [
      {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
    ];

    try {
      const usdcContract = new web3.eth.Contract(abi, process.env.REACT_APP_USDC_CONTRACT_ADDRESS);
      const result = await usdcContract.methods.balanceOf(process.env.REACT_APP_USDC_PAYER).call();
      setUSDCBalance(Number(result.toString()) / 1000000);
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: error.message }));
    }
  };
  useEffect(() => {
    calculateMaticBalance();
    calculateUSDCBalance();
  }, []);

  return (
    <Grid
      container
      justifyContent={"flex-end"}
      alignItems={"center"}
      spacing={2}
      boxShadow={4}
      padding={3}
    >
      <Grid item>
        <MKTypography variant="h6" textAlign="left">
          Payer: {process.env.REACT_APP_USDC_PAYER}{" "}
          <CopyButton text={process.env.REACT_APP_USDC_PAYER} />
        </MKTypography>
      </Grid>
      <Grid item>
        <MKTypography variant="body" textAlign="left">
          Matic Balance: {maticBalance}
        </MKTypography>
      </Grid>
      <Grid item>
        <MKTypography variant="body" textAlign="left">
          USDC Balance: ${usdcBalance}
        </MKTypography>
      </Grid>
    </Grid>
  );
};

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { db } from "configuration/firebase";
import { useSelector } from "react-redux";
import { CircularProgress, Container, Grid } from "@mui/material";

export const IsAdminValidator = ({ section, children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const validateAdmin = async () => {
    const snapshot = await db.collection("admins").doc(user.uid).get();
    if (!snapshot.exists) return;

    const data = snapshot.data();
    setIsAdmin(data[section]);
  };
  useEffect(() => {
    setIsAdmin(false);
    if (user) validateAdmin();
  }, [user, section]);

  return (
    <Container>
      <Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} mt={9}>
        <Grid item>{isAdmin ? children : <CircularProgress />}</Grid>
      </Grid>
    </Container>
  );
};

IsAdminValidator.propTypes = {
  children: PropTypes.object.isRequired,
  section: PropTypes.string.isRequired,
};

import { Container, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { AddressContainer } from "./components/AddressContainer";
import MKTypography from "components/MKTypography";

export const MyFiles = () => {
  const { wallets } = useSelector((state) => state.consumer);
  // we list the wallets and the tokens for each.
  // Then a button to download
  return (
    <Container>
      {wallets.length === 0 ? (
        <Grid
          container
          item
          xs={12}
          md={6}
          justifyContent="center"
          sx={{ mx: "auto", mb: 8, textAlign: "center" }}
        >
          <MKTypography variant="h2" mb={1}>
            You don&apos;t have any connected wallet.
          </MKTypography>
          <MKTypography variant="body1" color="text">
            You need a wallet and purchase data with a new order.{" "}
          </MKTypography>
        </Grid>
      ) : (
        <>
          {wallets.map((val) => (
            <AddressContainer address={val} key={val} />
          ))}
        </>
      )}
    </Container>
  );
};

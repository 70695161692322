import React from "react";

import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "reducer/uiReducer";
import MKTypography from "components/MKTypography";

export const CustomSnackbar = () => {
  const { snackbar } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={snackbar.severity} sx={{ width: "100%" }}>
        <MKTypography fontWeight={"bold"} verticalAlign={"middle"}>
          {snackbar.message}
        </MKTypography>
      </Alert>
    </Snackbar>
  );
};

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { DataUsageForm } from "./components/DataUsageForm";
import { LinkedWallets } from "./components/LinkedWallets";
import { WhyThis } from "components/WhyThis";
import MKButton from "components/MKButton";
import { ShowMeLink } from "components/ShowMeLink";

export const MyProfile = () => {
  return (
    <Container>
      <Grid
        container
        item
        xs={12}
        md={6}
        justifyContent="center"
        sx={{ mx: "auto", mb: 8, textAlign: "center" }}
      >
        <MKTypography variant="h2" mb={1}>
          My Profile
        </MKTypography>
        <MKTypography variant="h6" mb={1}>
          Review your linked wallets and your company and data usage declaration. In order to
          purchase data, you need to have at least one linked wallet and all your company and data
          usage declaration completed.
        </MKTypography>
      </Grid>
      <MKBox
        component="section"
        display="grid"
        position="relative"
        minHeight="90vh"
        borderRadius="xl"
        mr={{ xs: 0, lg: -2 }}
        mb={{ xs: 0, lg: 14 }}
        sx={{
          overflow: { xs: "hidden", lg: "visible" },
          placeItems: "center",
        }}
      >
        <MKBox
          component="img"
          src="https://images.unsplash.com/photo-1539803442075-48618f39bb3d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=963&amp;q=80"
          alt="lake house"
          width={{ xs: "100%", lg: "50%" }}
          height={{ xs: "100%", lg: "100%" }}
          position="absolute"
          right={0}
          bottom={{ xs: "-25%", lg: "unset" }}
          top={{ xs: 0, lg: "unset" }}
          sx={{
            objectFit: "cover",
            borderRadius: ({ borders: { borderRadius } }) => ({
              xs: borderRadius.xl,
            }),
          }}
        />

        <Container>
          <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
            <Grid item xs={12} lg={7} justifyContent="center" flexDirection="column">
              <MKBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                shadow="lg"
                borderRadius="xl"
                sx={{
                  backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                    rgba(white.main, 0.8),
                  backdropFilter: "saturate(200%) blur(30px)",
                  px: { xs: 3, sm: 6 },
                  py: { xs: 3, sm: 8 },
                  mb: { xs: 0, lg: 8 },
                  mt: { xs: 0, lg: -6 },
                }}
              >
                <MKTypography variant={"h4"} textAlign="center" mb={2}>
                  Company and data usage declaration
                  <WhyThis
                    text={
                      <>
                        Company and data usage declaration help us notify the Bripplex community
                        what you will be using the data for.
                        <br /> This data is mandatory and cannot be changed once saved.
                        <br />
                        <ShowMeLink href={"/docs/how"}>Read more...</ShowMeLink>
                      </>
                    }
                  />
                </MKTypography>

                <MKTypography variant="body" textAlign="left" mb={2}>
                  All fields are mandatory
                </MKTypography>
                <DataUsageForm />
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      {/* <Grid container direction={"column"} spacing={3}>
        <Grid item>
          <MKTypography variant="h5" color="text" mb={1}>
            Linked wallets{" "}
            <WhyThis
              text={
                <>
                  Linked wallets are needed to buy data. To prove ownership and be able to access
                  your purchased files, we use blockchain technology.
                  <br /> You need at least one crypto wallet to be able to own and view your data.
                  <br />
                  <ShowMeLink href={"/docs/payment"}>Read more...</ShowMeLink>
                </>
              }
            />
          </MKTypography>
        </Grid>
        <Grid item>
          <MKTypography variant="h5" color="text">
            Add a linked wallet to be able to buy data and access it.
          </MKTypography>
        </Grid>
        <Grid item>
          <LinkedWallets />
        </Grid>
      </Grid> */}
      <MKBox
        component="section"
        display="grid"
        position="relative"
        borderRadius="xl"
        mr={{ xs: 0, lg: -2 }}
        sx={{
          overflow: { xs: "hidden", lg: "visible" },
          marginTop: 4,
          placeItems: "center",
          padding: 1,
          backgroundColor: "#f0f2f5",
        }}
        mb={4}
      >
        <MKTypography variant="h5" color="text" mb={1}>
          Linked wallets{" "}
          <WhyThis
            text={
              <>
                Linked wallets are needed to buy data. To prove ownership and be able to access your
                purchased files, we use blockchain technology.
                <br /> You need at least one crypto wallet to be able to own and view your data.
                <br />
                <ShowMeLink href={"/docs/payment"}>Read more...</ShowMeLink>
              </>
            }
          />
        </MKTypography>
        <MKTypography variant="h5" color="text">
          Add a linked wallet to be able to buy data and access it.
        </MKTypography>
        <LinkedWallets />
      </MKBox>
    </Container>
  );
};

import React, { useEffect, useState } from "react";
import { SensorsDataset } from "./SensorsDataset";
import { db } from "configuration/firebase";
import { CircularProgress, Container, Grid } from "@mui/material";
import moment from "moment";
import MKTypography from "components/MKTypography";
import { DevicesDataset } from "./DevicesDataset";

export const AdminSensors = () => {
  const [stats, setStats] = useState([]);

  const getStats = async () => {
    const result = [];
    const query = db
      .collection("data")
      .doc("stats")
      .collection("snapshots")
      .orderBy("snapshotTimestamp", "asc");

    const snapshot = await query.get();
    for (const doc of snapshot.docs) {
      const data = doc.data();
      result.push({ ...data, ...data.uploads });
    }

    const now = await db.collection("data").doc("stats").get();

    result.push({ ...now.data(), ...now.data().uploads, snapshotTimestamp: moment().unix() });
    setStats(result);
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <>
      {stats.length == 0 ? (
        <CircularProgress />
      ) : (
        <Container>
          <Grid container direction={"column"}>
            <Grid item>
              <MKTypography variant={"h5"}>Sensors stats</MKTypography>
              <SensorsDataset stats={stats} />
            </Grid>
            <Grid item>
              <MKTypography variant={"h5"}>Devices stats</MKTypography>
              <DevicesDataset stats={stats} />
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";
import { BackButton } from "components/BackButton";
import { TopMenu } from "components/TopMenu";

export const MobileTerms = () => {
  return (
    <>
      <TopMenu />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <MKBox pb={6}>
                <BackButton />
              </MKBox>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Mobile App Terms and Conditions
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: July 2024
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    By downloading, installing, or using our mobile application (the
                    &ldquo;App&ldquo;), you agree to comply with and be bound by the following terms
                    and conditions of use. Please review these terms carefully before accessing or
                    using the App. If you do not agree to these terms, you may not access or use the
                    App.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    1. App Usage
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    <ul>
                      <li>
                        The App is provided for informational and analytical purposes only. You may
                        use the App solely for its intended purpose.
                      </li>
                    </ul>
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    2. Data Collection and Usage
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        The App may collect and transmit certain non-personal data, such as sensor
                        data (e.g., location, gyroscope, microphone, light, pedometer, barometer)
                        from your device, for the purpose of providing our services. By using the
                        App, you consent to the collection and transmission of such data.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    3. User Responsibilities
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        You are responsible for the use of the App and any data generated or
                        transmitted by the App.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    4. Intellectual Property
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        All content and materials provided through the App are the property of{" "}
                        <b>Halyard LLC</b> or its licensors and are protected by intellectual
                        property laws.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    5. Limitation of Liability
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        In no event shall <b>Halyard LLC</b> be liable for any direct, indirect,
                        incidental, special, or consequential damages arising out of or in any way
                        connected with the use of the App or any data collected or transmitted by
                        the App.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    6. Modification of Terms
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        <b>Halyard LLC</b> reserves the right to modify these terms and conditions
                        at any time. Any changes will be effective immediately upon posting within
                        the App. Your continued use of the App after the posting of changes
                        constitutes your acceptance of such changes.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    7. Governing Law
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        These terms and conditions shall be governed by and construed in accordance
                        with the laws of USA, without regard to its conflict of law provisions.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    8. Contact Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    <ul>
                      <li>
                        If you have any questions or concerns about these terms and conditions,
                        please contact us at{" "}
                        <MKTypography
                          variant="body2"
                          color="dark"
                          component="a"
                          href="mailto:contact@bripplex.com"
                          target="_blank"
                          rel="noreferrer"
                          sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                        >
                          contact@bripplex.com.
                        </MKTypography>
                        .
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mt={4} fontWeight="regular">
                    By using the App, you acknowledge that you have read, understood, and agree to
                    be bound by these terms and conditions.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

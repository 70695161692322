import { useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import Popover from "@mui/material/Popover";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { Icon } from "@mui/material";

export const WhyThis = ({ text }) => {
  const [popover, setPopover] = useState(null);
  const [popoverOrigin, setPopoverOrigin] = useState({
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
  });

  const togglePopover = ({ currentTarget }) => setPopover(currentTarget);
  const closePopover = () => setPopover(null);

  const popoverTemplate = (
    <Popover open={Boolean(popover)} anchorEl={popover} onClose={closePopover} {...popoverOrigin}>
      <MKBox bgColor="white" py={1.5} px={2} lineHeight={0.8}>
        <MKTypography variant="body2" color="text">
          {text}
        </MKTypography>
      </MKBox>
    </Popover>
  );

  return (
    <>
      <MKButton
        variant="text"
        iconOnly
        size="large"
        color="info"
        onClick={(event) => {
          togglePopover(event);
          setPopoverOrigin({
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }}
      >
        <Icon size="large">help_outline</Icon>
      </MKButton>
      {popoverTemplate}
    </>
  );
};

WhyThis.propTypes = {
  text: PropTypes.object.isRequired,
};

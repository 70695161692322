/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { Provider } from "react-redux";
import { store } from "reducer/store";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import { MetaMaskProvider } from "@metamask/sdk-react";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MetaMaskProvider
        debug={false}
        sdkOptions={{
          dappMetadata: {
            name: "Bripplex",
            url: window.location.href,
          },
          infuraAPIKey: process.env.REACT_APP_BLOCKCHAIN_INFURA_ID,
          // Other options
        }}
      >
        <App />
      </MetaMaskProvider>
    </Provider>
  </BrowserRouter>
);

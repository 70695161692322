/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Rental page components
import FaqCollapse from "pages/LandingPages/Rental/components/FaqCollapse";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              Frequently Asked Questions
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={10}>
            <FaqCollapse
              title="How is the price calculated?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              Price is calculated manually. Meaning <b>Bripplex</b>&apos;s sale staff will review
              any created order stats to define the price.Data consumer will be notified by email
              once the price has be defined to continue with payment
              <br />
              <br />
              How we calculate it? We take into considerations the amount of data submitted by each
              device, the location and the type of data the order includes. Meaning remote areas
              that are hard to find and covered only by a few devices will have more value than a
              big city covered by many devices.
              <br />
              <br />
              The price might be discussed directly between sales staff and the potential buyer to
              reach an agreement. At a future stage, we will try to move foardward into an automatic
              and instant price calculator.
            </FaqCollapse>
            <FaqCollapse
              title="Once I pay for the data, how do I access it?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              After you pay, a lot happens on <b>Bripplex</b> servers to take the order to the final
              stage. To access it, the buyer must sign a message with the wallet address used during
              the order purchase. This proves ownership of the NFT token that includes the metadata
              of all the files bought.
              <br />
              <br />
              After this successfull validation, files are available to download directly.
            </FaqCollapse>
            <FaqCollapse
              title="Is the purchased data mine forever?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              Well yes, as long as you don&apos;t sell it to someone else of course. Access to data
              is controlled by owning the NFT the data represents, so as long as you own the NFT,
              you have access to the data.
            </FaqCollapse>
            <FaqCollapse
              title="I adquire the data on secondary market. How can I download the files?"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              Owning the NFT of the data, proves you are the owner of it, but in order to access and
              download the data, you must be a registered data consumer at the <b>Bripplex</b>{" "}
              platform.
            </FaqCollapse>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;

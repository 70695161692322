import React, { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { ListItemIcon, ListItemText, Menu, MenuItem, Paper } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export const TopMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
      <Container>
        <Grid container flexDirection="row" alignItems="center">
          <MKTypography
            component={Link}
            href="/"
            variant="button"
            color="white"
            fontWeight="regular"
            py={0.8125}
            mr={2}
          >
            Bripplex.com
          </MKTypography>
          <MKButton
            variant="outlined"
            color="white"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}
            onClick={handleOpenMenu}
          >
            <MKBox component="i" color="white" className="fas fa-bars" />
          </MKButton>
          <Paper sx={{ width: 320, maxWidth: "100%" }}>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem component={Link} href="/#download" onClick={handleCloseMenu}>
                <ListItemIcon>
                  <DownloadForOfflineIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <MKTypography variant="body2">Download App</MKTypography>
                </ListItemText>
              </MenuItem>
              <MenuItem component={Link} href="/consumer" onClick={handleCloseMenu}>
                <ListItemIcon>
                  <ShoppingCartIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <MKTypography variant="body2">Data consumer</MKTypography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </Paper>
          <MKBox
            component="ul"
            display={{ xs: "none", lg: "flex" }}
            p={0}
            my={0}
            mx="auto"
            sx={{ listStyle: "none" }}
          >
            <MKBox component="li" marginX={2}>
              <MKButton component={Link} href="#download" variant="contained" color="primary">
                Download app
              </MKButton>
            </MKBox>
            <MKBox component="li">
              <MKButton component={Link} href="/consumer" variant="contained" color="warning">
                Data Consumers
              </MKButton>
            </MKBox>
          </MKBox>
          <MKBox
            component="ul"
            display={{ xs: "none", lg: "flex" }}
            p={0}
            m={0}
            sx={{ listStyle: "none" }}
          >
            <MKBox component="li">
              <MKTypography
                component={Link}
                href="https://www.twitter.com/bripplex"
                variant="button"
                p={1}
                onClick={(e) => e.preventDefault()}
              >
                <MKBox component="i" color="white" className="fab fa-twitter" />
              </MKTypography>
            </MKBox>
            <MKBox component="li">
              <MKTypography
                component={Link}
                href="https://www.facebook.com/bripplex"
                variant="button"
                p={1}
                onClick={(e) => e.preventDefault()}
              >
                <MKBox component="i" color="white" className="fab fa-facebook" />
              </MKTypography>
            </MKBox>
            <MKBox component="li">
              <MKTypography
                component={Link}
                href="https://www.instagram.com/bripplex"
                variant="button"
                p={1}
                onClick={(e) => e.preventDefault()}
              >
                <MKBox component="i" color="white" className="fab fa-instagram" />
              </MKTypography>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
};

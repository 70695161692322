import Web3 from "web3";

export const signMessage = async (address, provider, message) => {
  const signature = await provider.request({
    method: "personal_sign",
    params: [message, address],
  });
  return signature;
};

const myReadOnlyProvider = () => {
  const network = process.env.REACT_APP_BLOCKCHAIN_NETWORK;
  const infuraId = process.env.REACT_APP_BLOCKCHAIN_INFURA_ID;
  const provider = new Web3.providers.HttpProvider(`https://${network}.infura.io/v3/${infuraId}`);
  return provider;
};

export const toETH = (wei) => {
  return Web3.utils.fromWei(wei, "ether");
};

export const myReadOnlyWeb3 = () => {
  const provider = myReadOnlyProvider();
  const web3 = new Web3(provider);
  return web3;
};

export const shortenAddress = (address) => {
  if (!address) return;

  return address.slice(0, 5).concat("...").concat(address.slice(-5));
};

import { Container, Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinkedWallet } from "../LinkedWallet/LinkedWallet";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import { useSDK } from "@metamask/sdk-react";
import { addWallet } from "utils/consumers";
import { setWallets } from "reducer/consumerReducer";
import { showSnackbar } from "reducer/uiReducer";
import { logError } from "utils/errors";
import { ethers } from "ethers";

export const LinkedWallets = () => {
  const { wallets } = useSelector((state) => state.consumer);
  const { user } = useSelector((state) => state.auth);
  const { sdk, connected, connecting, provider, chainId } = useSDK();
  const dispatch = useDispatch();

  const handleAddWallet = async () => {
    try {
      const accounts = await sdk?.connect();
      if (accounts.length === 0) return;

      const wallet = ethers.getAddress(accounts[0]);

      // the new wallet is already added
      if (wallets.find((val) => val === wallet)) {
        dispatch(
          showSnackbar({
            severity: "info",
            title: "Wallet",
            message: "The wallet you are trying to add is already registered.",
          })
        );
        return;
      }

      await addWallet(user.uid, wallet);
      dispatch(setWallets([...wallets, wallet]));
      dispatch(
        showSnackbar({ severity: "success", title: "Wallet", message: "New wallet added." })
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          severity: "error",
          title: "Wallet",
          message: "There was an error saving your new wallet. Make sure Metamask is available.",
        })
      );
      logError("handleAddWallet", error);
    }
  };

  return (
    <Container>
      <Grid container spacing={2} mt={2}>
        {wallets.length > 0 ? (
          <>
            {wallets.map((address) => (
              <Grid item key={address} width={"100%"}>
                <LinkedWallet address={address} showDelete={true} isSelectable={false} />
              </Grid>
            ))}
          </>
        ) : (
          <Grid item>
            <MKTypography variant="body" textAlign="center">
              You don&apos;t have any wallets.
            </MKTypography>
          </Grid>
        )}
      </Grid>

      <MKBox display="flex" alignItems="center" justifyContent="center" my={4}>
        <MKButton
          variant="contained"
          color={"primary"}
          textAlign="center"
          onClick={handleAddWallet}
        >
          Add new wallet
        </MKButton>
      </MKBox>
    </Container>
  );
};

import { Grid } from "@mui/material";
import { CopyButton } from "components/CopyButton";
import MKTypography from "components/MKTypography";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";
import { myReadOnlyWeb3, toETH } from "utils/web3";

export const IssuerBalance = () => {
  const [maticBalance, setMaticBalance] = useState(0);
  const dispatch = useDispatch();
  const calculateBalance = async () => {
    try {
      const web3 = myReadOnlyWeb3();
      const result = await web3.eth.getBalance(process.env.REACT_APP_MINTER_ADDRESS, "latest");

      setMaticBalance(toETH(result));
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: error.message }));
    }
  };
  useEffect(() => {
    calculateBalance();
  }, []);

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      alignItems={"center"}
      spacing={2}
      boxShadow={4}
      padding={3}
    >
      <Grid item>
        <MKTypography variant="h6" textAlign="left">
          Minter: {process.env.REACT_APP_MINTER_ADDRESS}{" "}
          <CopyButton text={process.env.REACT_APP_MINTER_ADDRESS} />
        </MKTypography>
      </Grid>
      <Grid item>
        <MKTypography variant="body" textAlign="left">
          Matic Balance: {maticBalance}
        </MKTypography>
      </Grid>
    </Grid>
  );
};

import { logError } from "./errors";

const apiToken = process.env.REACT_APP_API_TOKEN;
const apiURL = process.env.REACT_APP_API_URL;

export const requestAuthMessage = async (address) => {
  const data = { address };
  const url = apiURL.concat("auth/getConsumerMessage");
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": apiToken,
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const requestAuthToken = async (address, signature) => {
  const data = { address, signature };
  const url = apiURL.concat("auth/getConsumerToken");

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": apiToken,
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const apiRequestPaymentIntent = async (uid, orderId, wallet) => {
  const data = { uid, orderId, wallet };
  const url = apiURL.concat("payment/create-payment-intent");

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": apiToken,
    },
    body: JSON.stringify(data),
  });

  const value = await response.json();

  if (!value.ok) {
    await logError("apiRequestPaymentIntent", value, null);
  }

  return value;
};

export const apiGetCurrentETHPrice = async () => {
  const defaultValue = 1510.95;

  try {
    const apiKey = process.env.REACT_APP_BLOCKCHAIN_ETHERSCAN_API;
    const url = `https://api.etherscan.io/api?module=stats&action=ethprice&apikey=${apiKey}`;
    const response = await fetch(url);
    const message = await response.json();
    if (message.status === "1") {
      return Number(message.result.ethusd);
    } else {
      return defaultValue;
    }
  } catch (error) {
    logError("apiGetCurrentETHPrice", error, null);
    return defaultValue;
  }
};

export const apiGetFiles = async (tokenId, uid, address, signature) => {
  const data = { tokenId, uid, address, signature };
  const url = apiURL.concat("files/getFiles");

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": apiToken,
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

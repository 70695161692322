/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";

export const RequestExplanation = () => {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
              Data Request
            </MKTypography>
            <MKTypography variant="body" color="text" mb={2}>
              Let the community know the area that you need data from, and we will notify them!
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <AboutUsOption
                icon="map"
                content={
                  <>
                    Step 1: Zoom in to the area that you want to request and select the shape icon
                    from the map&apos;s toolbar.
                  </>
                }
              />
              <AboutUsOption
                icon="crop_square"
                content={<>Step 2: Draw the area you need and choose all the sensors you need.</>}
              />
              <AboutUsOption
                icon="notifications"
                content={<>Step 3: save your selection and we will notify the community!</>}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

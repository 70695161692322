import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// Images
import Navbar from "components/NavBar";
import consumerRoutes from "../../consumerRoutes";

import MKBadge from "components/MKBadge";
import { BackButton } from "components/BackButton";
import { BackToTop } from "components/BackToTop";

export const WalletDoc = () => {
  return (
    <>
      <Navbar
        routes={consumerRoutes}
        action={{
          type: "internal",
          route: "/consumer/buyData",
          label: "buy data",
          color: "info",
        }}
        sticky
      />
      <Container>
        <MKBox paddingTop={15}>
          <BackButton />
        </MKBox>
        <Grid container flexDirection="column" alignItems="center" mt={4} spacing={2}>
          <Grid item lg={6} xs={12} sx={{ textAlign: "center", mx: "auto" }}>
            <MKBadge
              variant="contained"
              color="info"
              badgeContent="Bripplex"
              container
              circular
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" mb={1}>
              Your mobile wallet{" "}
            </MKTypography>
            <MKTypography variant="body" color="text">
              What can you do with it?
            </MKTypography>
          </Grid>
          <Grid item xs={12} width={"100%"} mb={4}>
            <MKTypography variant="h3" mt={4} textAlign="left" mb={4}>
              Getting paid
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              As soon as a data consumer purchases your submitted data, money (in the form of crypto
              stable coin <b>USDC</b>) will be transfered to your device. <br />
              <br />
              The amount of money transfered to your device is what the data consumer payed, minus{" "}
              <b>Bripplex</b> fee, distributed among all devices participating in the transaction.
              <br />
              <br />
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              Your <b>Bripplex</b> wallet only allows you to cash out your money to another crypto
              address. You can transfer it to another wallet that has more functionality, to a
              crypto exchange, etc.
            </MKTypography>

            <MKTypography variant="h3" mt={4} textAlign="left" mb={4}>
              What is USDC?
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              USD Coin (
              <a href="https://www.circle.com/en/usdc" target="_blank" rel="noopener noreferrer">
                <u>USDC</u>{" "}
              </a>
              ) is a digital stablecoin that is pegged to the US dollar and managed by Circle. USDC
              is a fiat-backed stablecoin, meaning it is backed by reserve assets in the traditional
              financial system, such as cash, cash equivalents, or securities. USDC is redeemable
              1:1 for US dollars and is backed by reserves.
            </MKTypography>

            <MKTypography variant="h3" mt={4} textAlign="left" mb={4}>
              Which blockchain does Bripplex uses?
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              Your <b>Bripplex</b> wallet operates under Polygon main blockchain (chainId: 137)
            </MKTypography>

            <MKTypography variant="h3" mt={4} textAlign="left" mb={4}>
              What can I do with USDC?
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              You can do a lot of things with USDC:
              <br />
              <br />
              <MKBox ml={4}>
                <ul>
                  <li>Pay merchants directly from your USDC wallet</li>
                  <li>Ppend like cash on many debit card providers</li>
                  <li>
                    Use a cryptocurrency exchange platform that supports USDC to sell your USDC for
                    USD and then withdraw the USD to your bank account
                  </li>
                  <li>Buy gift cards with USDC</li>
                  <li>Purchase games, movies, apps and more across the Xbox and Windows stores.</li>
                  <li>
                    Watch free live streaming gaming content including e-sports tournaments in games
                    like Fortnite, PUBG, and more.
                  </li>
                </ul>
              </MKBox>
              <br />
              And many more. <br />
              <br />
              We advice you to inverstigate more on the web before cashing out your earned money.
            </MKTypography>

            <BackToTop />
          </Grid>
        </Grid>
      </Container>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

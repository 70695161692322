/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";
// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { isMobile } from "react-device-detect";

export const AreYouSureModal = ({ show, setShow, title, message, onAccept, okButtonMessage }) => {
  const toggleModal = () => setShow(false);

  return (
    <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
      <Slide direction="down" in={show} timeout={500}>
        <MKBox
          position="relative"
          width={isMobile ? "100%" : "500px"}
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          bgColor="white"
          shadow="xl"
        >
          <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
            <MKTypography variant="h5">{title}</MKTypography>
            <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
          </MKBox>
          <Divider sx={{ my: 0 }} />
          <MKBox p={4}>
            <MKTypography variant="body2" color="secondary" fontWeight="regular">
              {message}
            </MKTypography>
          </MKBox>
          <Divider sx={{ my: 0 }} />
          <MKBox display="flex" justifyContent="space-between" p={1.5}>
            <MKButton variant="gradient" color="dark" onClick={toggleModal}>
              close
            </MKButton>
            <MKButton
              variant="gradient"
              color="info"
              onClick={() => {
                onAccept();
                toggleModal();
              }}
            >
              {okButtonMessage}
            </MKButton>
          </MKBox>
        </MKBox>
      </Slide>
    </Modal>
  );
};

AreYouSureModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.object.isRequired,
  okButtonMessage: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
};

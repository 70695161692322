import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// Images
import Navbar from "components/NavBar";
import consumerRoutes from "../../consumerRoutes";

import MKBadge from "components/MKBadge";
import { BackButton } from "components/BackButton";
import { BackToTop } from "components/BackToTop";

import img from "assets/images/docs/howWePay.png";

export const HowWePay = () => {
  const openseaLink = process.env.REACT_APP_BLOCKCHAIN_OPENSEA_LINK;

  return (
    <>
      <Navbar
        routes={consumerRoutes}
        action={{
          type: "internal",
          route: "/consumer/buyData",
          label: "buy data",
          color: "info",
        }}
        sticky
      />
      <Container>
        <MKBox paddingTop={15}>
          <BackButton />
        </MKBox>
        <Grid container flexDirection="column" alignItems="center" mt={4} spacing={2}>
          <Grid item lg={6} xs={12} sx={{ textAlign: "center", mx: "auto" }}>
            <MKBadge
              variant="contained"
              color="info"
              badgeContent="Bripplex"
              container
              circular
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" mb={1}>
              How we pay?{" "}
            </MKTypography>
            <MKTypography variant="body" color="text">
              Paying and getting payed is simple with <b>Bripplex</b>
            </MKTypography>
          </Grid>
          <Grid item xs={12} width={"100%"} mb={4}>
            <MKTypography variant="h3" mt={4} textAlign="left" mb={4}>
              Buying data
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              Registered data consumers are able to buy submited data after completing their
              profile. They first need to identify the data to purchase, or{" "}
              <a href="/consumer/dataRequest" rel="noopener noreferrer">
                <b>request data</b>
              </a>{" "}
              from the community for non explored areas.
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              Once the area is identify, data consumers create a buy order with the details of how
              many JSON files are included in the region they specified.
            </MKTypography>

            <MKTypography variant="h5" mt={4} textAlign="left">
              Pricing
            </MKTypography>
            <MKTypography variant="body" mt={4} textAlign="left">
              Pricing of the submited order is defined manually by <b>Bripplex</b>, because we still
              don&apos;t have enought data to define how much it cost each sensor&apos;s data.
              <br />
              <br /> For us is important the amount of data and the type of data submited, together
              with the location.
            </MKTypography>
            <MKTypography variant="body" mt={4} textAlign="left">
              Once the data consumer purchases and pays for the data, <b>Bripplex</b> retains a fee
              of the full price and distribute the rest among the devices that submited it. The more
              data submited by a choosen device, the more money it receives.
            </MKTypography>
          </Grid>
          <Grid item xs={12} width={"100%"}>
            <img src={img} alt={"Bripplex"} loading="lazy" width={"100%"} />
          </Grid>

          <Grid item xs={12} width={"100%"}>
            <MKTypography variant="body" mt={1} textAlign="left">
              The payed devices recieves a notification and are able to view their balance from the{" "}
              <b>Bripplex</b> app.
            </MKTypography>
            <MKTypography variant="body" mt={1} textAlign="left">
              The consumers are able to view and download the purchases files with the connected
              wallet in their profile. Since the purchased data generates an NFT on Polygon
              blockchain, only the owner of that NFT can view and download the files.
            </MKTypography>
          </Grid>
          <Grid item xs={12} width={"100%"} mb={4} mt={4}>
            <MKTypography variant="h5" mt={4} textAlign="left">
              NFT
            </MKTypography>
            <MKTypography variant="body" mt={4} textAlign="left">
              The generated NFT contains in its metadata, all the hashesof the files included in the
              purchase. And obviously, has an owner.
              <br />
              The owner of the wallet owning the NFT is a registered data consumer that can download
              the data after proving ownership of the associated wallet. And as any standard NFT, it
              can be sold and purchase on secondary market.
              <br /> <br />
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              Royalties for secondary market will be enforced on the smart contract and distributed
              between <b>Bripplex</b> and the devices that provided the data.
            </MKTypography>

            <MKTypography variant="body" mt={4} textAlign="left">
              <b>Bripplex</b> NFTs can be buy and sell in secondary market platforms like Opeansea.
              Our collection can be reviewed at{" "}
              <a href={openseaLink} target="_blank" rel="noopener noreferrer">
                Opensea
              </a>
              .
            </MKTypography>
            <BackToTop />
          </Grid>
        </Grid>
      </Container>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

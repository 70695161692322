/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";
import { BackButton } from "components/BackButton";
import { TopMenu } from "components/TopMenu";

export const EULA = () => {
  return (
    <>
      <TopMenu />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <MKBox pb={6}>
                <BackButton />
              </MKBox>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    End User License Agreement (EULA)
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: July 2024
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    This End User License Agreement (&ldquo;EULA&ldquo;) is a legal agreement
                    between you (referred to herein as &ldquo;User&ldquo; or &ldquo;you&ldquo;) and{" "}
                    <b>Halyard LLC</b> (referred to herein as &ldquo;Company&ldquo; or
                    &ldquo;we&ldquo;) governing your use of the <b>Bripplex</b> mobile application
                    (the &ldquo;App&ldquo;).
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    1. License Grant
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Subject to the terms and conditions of this EULA, Company grants you a limited,
                    non-exclusive, non-transferable, revocable license to use the App solely for
                    your personal, non-commercial purposes.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    2. Restrictions
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    You may not:
                    <ul>
                      <li>
                        Modify, adapt, translate, reverse engineer, decompile, disassemble, or
                        create derivative works based on the App;
                      </li>
                      <li>
                        Copy, distribute, transfer, sublicense, lease, lend, or rent the App to any
                        third party;
                      </li>
                      <li>
                        Remove, alter, or obscure any copyright, trademark, or other proprietary
                        notices contained in the App;
                      </li>
                      <li>
                        Use the App in any manner that violates applicable laws or regulations.
                      </li>
                    </ul>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    3. Intellectual Property Rights
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    The App and all intellectual property rights therein are and shall remain the
                    exclusive property of Company and its licensors. Nothing in this EULA grants you
                    any right or license to use any of Company&apos;`s trademarks, logos, or other
                    branding elements.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    4. Disclaimer of Warranty
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    THE APP IS PROVIDED &ldquo;AS IS&ldquo; WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
                    IMPLIED. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, COMPANY DISCLAIMS
                    ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT
                    NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                    AND NON-INFRINGEMENT.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    5. Limitation of Liability
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    IN NO EVENT SHALL COMPANY BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                    CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE
                    OF THE APP, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, DATA, OR BUSINESS
                    INTERRUPTION.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    6. Governing Law
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    This EULA shall be governed by and construed in accordance with the laws of USA,
                    without regard to its conflict of law provisions.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    7. Termination
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Company may terminate this EULA at any time without notice if you fail to comply
                    with any term or condition herein. Upon termination, you must cease all use of
                    the App and destroy all copies of the App in your possession or control.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    8. Entire Agreement
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    This EULA constitutes the entire agreement between you and Company regarding
                    your use of the App and supersedes all prior or contemporaneous understandings
                    and agreements, whether written or oral. By downloading, installing, or using
                    the App, you acknowledge that you have read, understood, and agree to be bound
                    by this EULA.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

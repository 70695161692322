/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import consumerRoutes from "consumerRoutes";
import footerRoutes from "footer.routes";
import Navbar from "components/NavBar";
import { BackButton } from "components/BackButton";

export const DataPrivacyTerms = () => {
  return (
    <>
      <Navbar
        routes={consumerRoutes}
        action={{
          type: "internal",
          route: "/consumer/buyData",
          label: "Buy Data",
          color: "dark",
        }}
        transparent
        light={false}
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <MKBox pb={6}>
                <BackButton />
              </MKBox>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Data Privacy
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: Sept 07 2024
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Welcome to Bripplex (&quot;we&quot;, &quot;our&quot;, or &quot;us&quot;). We are
                    committed to protecting the privacy and security of your data. This Privacy
                    Policy explains how we collect, use, and share data collected through our mobile
                    application.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Data Collection
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Our mobile application collects various types of data from your device,
                    including but not limited to:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Location: Latitude and Longitude coordinates, including altitude, heading
                        and speed.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Microphone: Surrounding loudness in dB.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Accelerometer: Measures device acceleration in three-dimensional space,
                        meaning any movement or vibration.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Pedometer: Counts the number of steps taken.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Barometer: Measures pressure in hectopascals (hPa).
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Device Motion: Measures device motion. All data is presented in terms of
                        three axes that run through a device.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Magnetometer: Measures changes in the magnetic field specified in microtesla
                        (μT).
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Gyroscope: Represents the rotation along that particular axis measured in
                        degrees per second (°/s).
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Light: Measures ambient light.
                      </MKTypography>
                    </MKBox>
                    {/* <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Carrier: Get carrier and cellular service provider information.
                      </MKTypography>
                    </MKBox> */}
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Compass: Get heading in magnetic and true north degrees.
                      </MKTypography>
                    </MKBox>
                    {/* <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Installed Apps: Gets the list of installed apps on the device.
                      </MKTypography>
                    </MKBox> */}
                  </MKBox>
                  <MKTypography variant="body2" color="text">
                    A full detailed list can also be reviewed{" "}
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="https://www.bripplex.com/docs/what"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      here.
                    </MKTypography>
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    Data Anonymity
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    We take your privacy seriously. All data collected by our application is fully
                    anonymized. We do not collect any personally identifiable information (PII) such
                    as your name, email address, phone number, or device identifiers. The data
                    cannot be linked back to you or your device in any way.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    Data Usage
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    The data we collect is uploaded to our servers and made available to data
                    consumers, such as meteorological researchers, academic institutions, and other
                    entities for legitimate research and analysis purposes. Data consumers may use
                    this data to enhance their studies, models, and applications.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    Data Sharing and Access
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Data is only shared with data consumers who have paid for access to the
                    anonymized data. The revenue generated from these transactions is distributed to
                    the mobile device owners who contributed data, minus a fee retained by Bripplex
                    for operational costs.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    Data Security
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    We implement robust security measures to protect the data collected and stored
                    on our servers. This includes encryption, access controls, and regular security
                    audits. However, please be aware that no method of transmission over the
                    internet or electronic storage is 100% secure.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    User Rights and Choices
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Since the data collected is fully anonymized, it is not possible to identify any
                    individual user from the data. Therefore, the right to access, correct, or
                    delete specific personal data does not apply. However, users have the option to
                    opt out of data collection by uninstalling the application at any time.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    Changes to This Privacy Policy
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    We may update this Privacy Policy from time to time to reflect changes in our
                    practices or for other operational, legal, or regulatory reasons. When we update
                    the policy, we will revise the &ldquo;Last Updated&ldquo; date at the top of
                    this page.
                  </MKTypography>

                  <MKTypography variant="h5" mt={6} mb={3}>
                    Contact Us
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    If you have any questions or concerns about this Privacy Policy or our data
                    practices, please contact us at
                    <MKTypography
                      variant="body2"
                      color="dark"
                      component="a"
                      href="mailto:contact@bripplex.com"
                      target="_blank"
                      rel="noreferrer"
                      sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
                    >
                      contact@bripplex.com
                    </MKTypography>
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

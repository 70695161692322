import React from "react";
import PropTypes from "prop-types";
import { LineChart } from "@mui/x-charts/LineChart";
import moment from "moment";
import { isMobile } from "react-device-detect";

export const SensorsDataset = ({ stats }) => {
  const stackStrategy = {
    stack: "snapshotTimestamp",
    area: true,
    stackOffset: "none", // To stack 0 on top of others
  };

  const customize = {
    height: 400,
    width: isMobile ? 300 : 800,
    legend: { hidden: true },
    margin: { top: 5 },
    stackingOrder: "descending",
  };

  return (
    <LineChart
      xAxis={[
        {
          dataKey: "snapshotTimestamp",
          valueFormatter: (value) => moment.unix(value).toDate().toString(),
        },
      ]}
      series={Object.keys(stats[stats.length - 1].uploads).map((key) => ({
        dataKey: key,
        label: key.toString(),
        showMark: false,
        ...stackStrategy,
      }))}
      dataset={stats}
      {...customize}
    />
  );
};

SensorsDataset.propTypes = {
  stats: PropTypes.object.isRequired,
};

import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import {
  AppBar,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Icon,
  Slide,
  Toolbar,
} from "@mui/material";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { IncludedList } from "../Order/components/IncludedList";
import { OrderHeader } from "../Order/components/OrderHeader";
import MKBox from "components/MKBox";
import { LinkedWallet } from "../../../MyProfile/components/LinkedWallet";
import { useSelector } from "react-redux";
import { CreditCardPayment } from "../CreditCardPayment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const OrderCheckout = ({ order, open, setOpen }) => {
  const [selectedWallet, setSelectedWallet] = useState();
  const { wallets } = useSelector((state) => state.consumer);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (wallets.length > 0) setSelectedWallet(wallets[0]);
  }, [wallets]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{ zIndex: 0 }}
    >
      <AppBar sx={{ position: "relative" }} color="secondary">
        <Toolbar>
          <MKButton
            edge="start"
            color="secondary"
            iconOnly
            onClick={handleClose}
            aria-label="close"
          >
            <Icon size="large">close</Icon>
          </MKButton>
          <MKTypography sx={{ ml: 2, flex: 1 }} variant="h6" color="white">
            Order {order.id}
          </MKTypography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Container>
          <MKBox sx={{ border: "1px solid grey", borderRadius: 8 }} mb={2} padding={4}>
            <OrderHeader order={order} />
            <IncludedList order={order} showJSON={false} />
          </MKBox>

          <Grid container direction={"column"} paddingTop={2} spacing={2}>
            <Grid item>
              <MKTypography variant="h5">Will be minted to the following wallet:</MKTypography>
            </Grid>
            {wallets.map((wallet) => (
              <Grid item key={wallet}>
                <LinkedWallet
                  address={wallet}
                  showDelete={false}
                  isSelectable={true}
                  selected={selectedWallet === wallet}
                  setSelected={() => setSelectedWallet(wallet)}
                />
              </Grid>
            ))}

            <Grid item>
              <CreditCardPayment order={order} selectedWallet={selectedWallet} />
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

OrderCheckout.propTypes = {
  order: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import { sensorsList } from "utils/sensors";

export const OrderHeader = ({ order }) => {
  const [totals, setTotals] = useState({ files: 0, rows: 0 });

  const calculateTotals = () => {
    const totalRows = sensorsList.reduce((total, sensor) => {
      if (order.includedSensors[sensor.type]) {
        if (order[sensor.type]?.rows) {
          // if we have stats for this sensor, we add them
          total = total + order[sensor.type]?.rows;
        }
      }
      return total;
    }, order.location.rows);
    const totalFiles = sensorsList.reduce((total, sensor) => {
      if (order.includedSensors[sensor.type]) {
        if (order[sensor.type]?.files) {
          // if we have stats for this sensor, we add them
          total = total + order[sensor.type]?.files;
        }
      }
      return total;
    }, order.location.files);
    setTotals({ rows: totalRows, files: totalFiles });
  };

  useEffect(() => {
    if (order) calculateTotals();
  }, [order]);

  return (
    <MKBox mb={2}>
      <MKTypography variant="body2" color={"warning"} fontWeight="regular" display={"inline"}>
        This order includes <b>{totals.rows} </b>
        entries from one or multiple devices.{" "}
      </MKTypography>
      <MKTypography variant="body2" color="warning" fontWeight="regular" display={"inline"}>
        Grouped in <b>{totals.files}</b> JSON files.
      </MKTypography>
    </MKBox>
  );
};
OrderHeader.propTypes = {
  order: PropTypes.object.isRequired,
};

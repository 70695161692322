import { db } from "configuration/firebase";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdminOrder } from "./components/AdminOrder";
import MKBox from "components/MKBox";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Grid } from "@mui/material";

export const AdminOrders = () => {
  const [openedOrders, setOpenedOrders] = useState([]);
  const [closedOrders, setClosedOrders] = useState([]);
  const dispatch = useDispatch();

  const getAdminOrders = async () => {
    try {
      const consumerSnapshot = await db.collection("consumers").get();
      for (const consumer of consumerSnapshot.docs) {
        const orderSnapshot = await db
          .collection("consumers")
          .doc(consumer.id)
          .collection("orders")
          .get();
        for (const order of orderSnapshot.docs) {
          const data = order.data();
          if (data.status < 8)
            setOpenedOrders((current) => [...current, { ...data, ...consumer.data() }]);
          else {
            setClosedOrders((current) => [...current, { ...data, ...consumer.data() }]);
          }
        }
      }
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: error.message }));
    }
  };

  useEffect(() => {
    getAdminOrders();
  }, []);

  return (
    <MKBox mt={3}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge badgeContent={openedOrders.length} color="primary" showZero>
            Opened orders
          </Badge>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            {openedOrders.map((order) => (
              <Grid item key={order.id}>
                <AdminOrder order={order} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Badge badgeContent={closedOrders.length} color="primary" showZero>
            Closed orders
          </Badge>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            {closedOrders.map((order) => (
              <Grid item key={order.id}>
                <AdminOrder order={order} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </MKBox>
  );
};

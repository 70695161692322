import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { shortenAddress, toETH } from "utils/web3";
import { CopyButton } from "components/CopyButton";
import { Link } from "react-router-dom";

export const PaymentListItem = ({ payment }) => {
  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={4}
      padding={2}
      margin={2}
      style={{ backgroundColor: "#d9d4d4" }}
    >
      <Grid item>
        Device: {shortenAddress(payment.device)} <CopyButton text={payment.device} />
      </Grid>
      <Grid item>$: {payment.value / 1000000}</Grid>
      <Grid item>
        tx:
        <Link to={`https://polygonscan.com/tx/${payment.tx}`} target="self">
          {shortenAddress(payment.tx)}
        </Link>
        <CopyButton text={payment.tx} />
      </Grid>
      <Grid item>
        gas: matic {toETH(BigInt(payment.gasUsed) * BigInt(payment.effectiveGasPrice)).toString()}
      </Grid>
    </Grid>
  );
};

PaymentListItem.propTypes = {
  payment: PropTypes.object.isRequired,
};

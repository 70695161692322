import { Checkbox, Container, FormControlLabel, FormGroup, Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrderIncludedSensors } from "reducer/consumerReducer";

export const OrderIncludedSensors = () => {
  const { orderIncludedSensors } = useSelector((state) => state.consumer);
  const dispatch = useDispatch();
  const handleChange = (key, value) => {
    dispatch(setOrderIncludedSensors({ ...orderIncludedSensors, [key]: value }));
  };
  return (
    <Container>
      <MKBox pt={1} textAlign="center">
        <MKTypography variant={"h5"}>Data to be included</MKTypography>
      </MKBox>
      <Grid container direction={"row"} spacing={1} margin={1}>
        {Object.entries(orderIncludedSensors).map((entry) => (
          <Grid item key={entry[0]} xs={6} md={4} lg={3}>
            <MKTypography variant="body1" color="dark">
              <FormControlLabel
                value={entry[1]}
                disabled={entry[0] === "location"}
                control={<Checkbox defaultChecked />}
                onChange={() => handleChange(entry[0], !entry[1])}
                label={entry[0].charAt(0).toUpperCase() + entry[0].slice(1)}
              />
            </MKTypography>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

import React from "react";

import PropTypes from "prop-types";

import { InfoWindow } from "@react-google-maps/api";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import { DataQualityIndicator } from "../DataQualityIndicator";

export const AreaInfoWindows = ({ area, onZoomHandler, onClose }) => {
  return (
    <InfoWindow position={{ lat: area.center.lat, lng: area.center.lng }} onCloseClick={onClose}>
      <MKBox margin={1}>
        <DataQualityIndicator data={area} />

        <MKButton variant={"outlined"} onClick={onZoomHandler} color="primary" sx={{ marginY: 1 }}>
          View details
        </MKButton>
      </MKBox>
    </InfoWindow>
  );
};

AreaInfoWindows.propTypes = {
  area: PropTypes.object.isRequired,
  onZoomHandler: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

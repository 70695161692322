import React, { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Order } from "./components/Order";
import { useSelector } from "react-redux";
import MKButton from "components/MKButton";
import { Link } from "react-router-dom";
import { Checkbox } from "@mui/material";

export const MyOrders = () => {
  const { orders } = useSelector((state) => state.consumer);
  const [checked, setChecked] = useState(false);
  return (
    <MKBox component="section" py={{ xs: 12, lg: 10 }}>
      <Container>
        {orders.length === 0 ? (
          <Grid
            container
            item
            xs={12}
            md={6}
            justifyContent="center"
            sx={{ mx: "auto", mb: 8, textAlign: "center" }}
          >
            <MKTypography variant="h2" mb={1}>
              You don&apos;t have any orders
            </MKTypography>
            <MKTypography variant="body1" color="text">
              To create an order click on{" "}
              <MKButton
                variant={"contained"}
                color={"info"}
                component={Link}
                to="/consumer/buyData"
              >
                Buy data
              </MKButton>
            </MKTypography>
          </Grid>
        ) : (
          <>
            <MKBox mb={2}>
              <Checkbox
                color="secondary"
                checked={checked}
                onChange={() => setChecked((current) => !current)}
              />{" "}
              Show completed orders
            </MKBox>
            <Grid
              container
              item
              xs={12}
              md={6}
              justifyContent="center"
              sx={{ mx: "auto", mb: 8, textAlign: "center" }}
            >
              <MKTypography variant="h2" mb={1}>
                You have {orders.length} order{orders.length > 1 && "s"}
              </MKTypography>
              <MKTypography variant="body1" color="text">
                You can review, cancel or complete payment for your in progress orders
              </MKTypography>
            </Grid>
            <Grid container item xs={12}>
              {orders.map((order) => (
                <>{(checked || order.status < 2) && <Order key={order.id} order={order} />}</>
              ))}
            </Grid>
          </>
        )}
      </Container>
    </MKBox>
  );
};

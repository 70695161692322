/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";
import { GoogleAuthButton } from "components/GoogleAuthButton";
import { WalletAuthButton } from "components/WalletAuthButton";
import { FacebookAuthButton } from "components/FacebookAuthButton";

import { useDispatch } from "react-redux";
import { showBackdrop } from "reducer/uiReducer";
import { hideBackdrop } from "reducer/uiReducer";
import { showSnackbar } from "reducer/uiReducer";
import { signInWithEmailAndPassword } from "auth/auth";

function SignInCover() {
  const [data, setData] = useState({ email: "", password: "", password2: "" });
  const dispatch = useDispatch();
  const handleDataChange = (e, type) => {
    setData((current) => ({ ...current, [type]: e.target.value }));
  };
  const handleEmail = async () => {
    dispatch(showBackdrop());
    try {
      await signInWithEmailAndPassword(data.email, data.password);
    } catch (error) {
      console.log(error);
      dispatch(
        showSnackbar({
          severity: "error",
          message:
            "There was an error signing you up. Please verify your user name and password and try again.",
        })
      );
    } finally {
      dispatch(hideBackdrop());
    }
  };
  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MKTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookAuthButton />
              </MKTypography>
            </Grid>
            <Grid item xs={2}>
              <MKTypography component={MuiLink} href="#" variant="body1" color="white">
                <WalletAuthButton />
              </MKTypography>
            </Grid>
            <Grid item xs={2}>
              <GoogleAuthButton />
            </Grid>
          </Grid>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to Sign In
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput
                type="email"
                label="Email"
                value={data.email}
                onChange={(e) => handleDataChange(e, "email")}
                variant="standard"
                fullWidth
                placeholder="john@example.com"
                InputLabelProps={{ shrink: true }}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Password"
                variant="standard"
                onChange={(e) => handleDataChange(e, "password")}
                value={data.password}
                fullWidth
                placeholder="************"
                InputLabelProps={{ shrink: true }}
              />
            </MKBox>

            <MKBox mt={4} mb={1}>
              <MKButton variant="gradient" color="info" fullWidth onClick={handleEmail}>
                sign in
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/signup"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default SignInCover;

/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { db } from "configuration/firebase";
import { verifyEmailAndPassword } from "auth/auth";
import { useDispatch, useSelector } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";

export const EmailVerification = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleEmailVerification = async () => {
    if (!user) return;

    await db
      .collection("consumers")
      .doc(user.uid)
      .set({ EmailVerification: false }, { merge: true });
    const sent = await verifyEmailAndPassword();
    if (sent) {
      dispatch(
        showSnackbar({
          severity: "success",
          title: "Email verification",
          message: "We have just sent you an email. Check your inbox and confirm your email.",
        })
      );
    } else {
      dispatch(
        showSnackbar({
          severity: "error",
          title: "Email verification",
          message: "An email was already sent. Please check your inbox or retry in a few minutes.",
        })
      );
    }
  };
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Email Verification
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            An email has been sent to your address.
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form">
            <MKTypography variant={"body2"}>
              Click on the link on the e-mail and after verification you are ready to go!
            </MKTypography>
            <MKBox mt={6} mb={1}>
              <MKButton variant="gradient" color="info" fullWidth onClick={handleEmailVerification}>
                Resend email!
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
};

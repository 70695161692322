import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MKButton from "components/MKButton";
import { AreYouSureModal } from "components/AreYouSureModal";
import { db } from "configuration/firebase";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";

export const OrderStatus = ({ order }) => {
  const [status, setStatus] = useState(order.status);
  const [showModal, setShowModal] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setStatus(e.target.value);
    setDisableButton(false);
  };

  const handleStatusChange = () => {
    setShowModal(true);
  };

  const handleOnAccept = async () => {
    try {
      setDisableButton(true);
      await db
        .collection("consumers")
        .doc(order.uid)
        .collection("orders")
        .doc(order.id)
        .set({ status }, { merge: true });
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: error.message }));
    } finally {
      setDisableButton(true);
    }
  };

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={4}
      style={{ backgroundColor: "white" }}
      padding={2}
      borderRadius={5}
      boxShadow={4}
    >
      <Grid item xs={6}>
        <InputLabel>Order status</InputLabel>
        <Select fullWidth value={status} label="Order status" onChange={handleChange}>
          <MenuItem value={0}>0 - None</MenuItem>
          <MenuItem value={1}>1 - Order with price</MenuItem>
          <MenuItem value={2}>2 - Order paid by user</MenuItem>
          <MenuItem value={3}>3 - Mint NFT</MenuItem>
          <MenuItem value={4}>4 - Generate Metadata and static map</MenuItem>
          <MenuItem value={5}>5 - Generate download File</MenuItem>
          <MenuItem value={6}>6 - Pay devices</MenuItem>
          <MenuItem value={7}>7 - Notify devices</MenuItem>
          <MenuItem value={8}>8 - Completed</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={6}>
        <MKButton
          variant="gradient"
          color={"warning"}
          onClick={handleStatusChange}
          disabled={disableButton}
        >
          Change status
        </MKButton>
      </Grid>
      <AreYouSureModal
        show={showModal}
        setShow={setShowModal}
        okButtonMessage={"Ok"}
        message={`Are you sure you want to change the order status to ${status}?`}
        onAccept={handleOnAccept}
      />
    </Grid>
  );
};

OrderStatus.propTypes = {
  order: PropTypes.object.isRequired,
};

import React, { useState } from "react";

import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { WhyThis } from "components/WhyThis";
import { OrderCheckout } from "../../../OrderCheckout";
import { getFormatedPrice } from "utils/price";

export const OrderPrice = ({ order }) => {
  const [openCheckout, setOpenCheckout] = useState(false);
  const handleCheckout = () => {
    setOpenCheckout(true);
  };
  return (
    <Grid item xs={12} lg={4} textAlign={"center"}>
      {order.status === 0 ? (
        <Grid container direction={"row"} alignContent={"center"} justifyContent={"center"}>
          <Grid item>
            <MKTypography variant="h6">Price is being calculated</MKTypography>
          </Grid>
          <Grid item>
            <WhyThis
              text={
                <>
                  Bripplex needs to generate more sales in order to be able to determine prices
                  automatically.
                  <br /> Until then, we will be contacting you over e-mail to reach a deal on the
                  price.
                  <br /> You will be notified once the order is ready to be settled.
                </>
              }
            />
          </Grid>
        </Grid>
      ) : (
        <MKTypography variant="h4">Total</MKTypography>
      )}

      <MKTypography variant="h1">
        <MKBox component="small">$</MKBox>
        {getFormatedPrice(order.price) || 0.0}
      </MKTypography>
      {order.status < 2 && (
        <MKButton
          variant="gradient"
          color="error"
          size="large"
          sx={{ my: 2 }}
          disabled={order.status !== 1}
          onClick={handleCheckout}
        >
          Checkout
        </MKButton>
      )}
      <OrderCheckout order={order} open={openCheckout} setOpen={setOpenCheckout} />
    </Grid>
  );
};

OrderPrice.propTypes = {
  order: PropTypes.object.isRequired,
};

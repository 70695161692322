import { facebookAuthProvider } from "configuration/firebase";
import { twitterAuthProvider } from "configuration/firebase";
import { db } from "configuration/firebase";
import { auth } from "configuration/firebase";
import { googleAuthProvider } from "configuration/firebase";
import moment from "moment";
import { setUser } from "reducer/authReducer";
import { store } from "reducer/store";
import { showSnackbar } from "reducer/uiReducer";
import { logError } from "utils/errors";
import { jwtDecode } from "jwt-decode";

import { ethers } from "ethers";
import { localStorageGet } from "utils/localStorage";
import { requestAuthToken } from "utils/api";
import { requestAuthMessage } from "utils/api";
import { localStorageAdd } from "utils/localStorage";
import { signMessage } from "utils/web3";

export const authStartSignUpUserMedia = async (mediaProvider, popUp = true) => {
  try {
    let provider;
    let providerId;
    switch (mediaProvider) {
      case "google":
        provider = new googleAuthProvider();
        providerId = "google.com";
        break;
      case "facebook":
        provider = new facebookAuthProvider();
        provider.addScope("public_profile");
        provider.addScope("email");
        providerId = "facebook.com";
        break;
      case "twitter":
        provider = new twitterAuthProvider();
        providerId = "twitter.com";
        break;
      default:
        provider = new googleAuthProvider();
        providerId = "google.com";
        break;
    }

    let result;

    try {
      if (popUp) result = await auth.signInWithPopup(provider);
      else {
        await auth.signInWithRedirect(provider);
        result = await auth.getRedirectResult();
      }
    } catch (error) {
      await logError("authStartSignUpUserMedia", error, null);
    }

    const { user } = result;
    const { displayName, email, uid, phoneNumber, photoURL, refreshToken } = user;

    // we store user data
    const data = {
      auth: mediaProvider,
      displayName,
      email,
      uid,
      phoneNumber,
      photoURL,
      refreshToken,
      providerId,
      lastLogin: moment().toDate(),
    };
    await saveUserData(uid, data);
  } catch (error) {
    const { message } = error;

    if (message.includes("popup has been closed")) {
      return;
    }
    logError(`Signing ${mediaProvider} user`, error);
  }
};

const saveLastLogin = async (uid) => {
  try {
    await db
      .collection("consumers")
      .doc(uid)
      .set({ lastLogin: moment().toDate() }, { merge: true });
  } catch (error) {
    await logError("Saving last login", error, uid);
  }
};

const saveUserData = async (uid, data) => {
  try {
    const userRef = db.collection("consumers").doc(uid);
    const snapshot = await userRef.get();
    if (!snapshot.exists) await db.collection("consumers").doc(uid).set(data);
  } catch (error) {
    await logError("Saving auth data", error, uid);
  }
};

export const authStartLogOut = async () => {
  await auth.signOut();
  store.dispatch(showSnackbar({ severity: "success", message: "You have been logged out." }));
};

export const registerCompanyChanges = (uid) => {
  const ref = db.collection("consumers").doc(uid);
  return ref.onSnapshot((snapshot) => {
    if (snapshot.empty) return;
    const data = snapshot.data();
    delete data.lastLogin;
    store.dispatch(setUser(data));
  });
};

const isTokenExpired = (token) => {
  const decoded = jwtDecode(token);
  const now = moment().unix();
  const { exp } = decoded;
  return now > exp;
};

// signs a wallet as user
export const authStartSignUpWallet = async (add, provider) => {
  try {
    const address = ethers.getAddress(add);
    // we search first for token stored on storage, because this might be an existing user
    const storage = localStorageGet(address);
    if (storage?.token) {
      // we need to verify if the token is still valid, because it might have expired.
      let token = storage.token;
      if (!isTokenExpired(token)) {
        return await auth.signInWithCustomToken(token);
      }
    }

    // At this point, we dont have a token we can use, so we request a message, sign it and get a new token
    const response = await requestAuthMessage(address);

    // if we don't have a provider, then we will fail
    const signature = await signMessage(add, provider, response.data);
    const signatureResponse = await requestAuthToken(address, signature);
    const token = signatureResponse.data;
    const result = await auth.signInWithCustomToken(token);

    // we store the token on local Storage to support user switching
    localStorageAdd(address, { token });
    const { user } = result;
    const data = {
      uid: user.uid,
      providerId: "web3",
      refreshToken: user.refreshToken,
      lastLogin: moment().toDate(),
      wallets: [user.uid], // we add this uid as the preferred wallet.
    };
    await saveUserData(user.uid, data);
  } catch (error) {
    logError("Signing web3 user ", error, add);
    throw error;
  }
};

export const authStartSignUpEmailPassword = async (userEmail, password) => {
  try {
    const result = await auth.createUserWithEmailAndPassword(userEmail, password);
    const { user } = result;
    const { email, uid, refreshToken, emailVerified } = user;
    const data = {
      auth: "password",
      displayName: email,
      emailVerified,
      email,
      uid,
      refreshToken,
    };

    await saveUserData(uid, data);
    // we store in firestore the last login time
    // await saveLastLogin(uid);
  } catch (error) {
    logError("authStartSignUpEmailPassword", error, null);

    if (error.message.includes("email address is already in use by another account")) {
      store.dispatch(
        showSnackbar({
          severity: "warning",
          title: "User Login",
          message: "Provided email is already in use by another account. Try login in.",
        })
      );
      return;
    }

    const { uid } = store.getState().auth;
    if (!uid) {
      store.dispatch(
        showSnackbar({
          severity: "error",
          title: "Login error",
          message:
            "We have an error trying to log you in. We are sorry for the inconvenience. Please refresh and try again.",
        })
      );
    }

    logError(`Signing email user`, error);
  }
};

export const signInWithEmailAndPassword = async (email, password) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (error) {
    if (
      error.message.includes("The password is invalid or the user does not have a password") ||
      error.message.includes("auth/invalid-login-credentials")
    ) {
      store.dispatch(
        showSnackbar({
          severity: "warning",
          title: "User Login",
          message: "Provided credentials are not ok. Please review and retry.",
        })
      );
      return;
    }
    if (error.message.includes("There is no user record corresponding to this identifier")) {
      store.dispatch(
        showSnackbar({
          severity: "warning",
          title: "User Login",
          message: "Provided credentials are not ok. Please review and retry.",
        })
      );
      return;
    }
    logError("signInWithEmailAndPassword", error, null);
    store.dispatch(
      showSnackbar({
        severity: "warning",
        title: "User Login",
        message: "There was an error trying to sign you in. Review your credentials and try again.",
      })
    );
  }
};

export const verifyEmailAndPassword = async () => {
  try {
    const { uid } = auth.currentUser;
    const ref = db.collection("consumers").doc(uid);
    const snapshot = await ref.get();
    if (snapshot.exists) {
      const data = snapshot.data();
      if (!data.emailVerificationSent) {
        await auth.currentUser.sendEmailVerification({
          url: process.env.REACT_APP_CONTINUE_URL_EMAIL_VERFICATION,
        });
        await ref.set({ emailVerificationSent: true }, { merge: true });
      }
      return true;
    }
    return false;
  } catch (error) {
    logError("verifyEmailAndPassword", error, null);
    return false;
  }
};

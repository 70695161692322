import React from "react";
import { Card, Icon } from "@mui/material";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

export const Included = ({ label, description, rows, files, onViewHandler, icon }) => {
  return (
    <Card elevation={4} sx={{ boxShadow: 4 }}>
      <MKBox display="flex" py={1} pr={1} lineHeight={0} padding={2}>
        <MKTypography variant="body1" color="dark">
          <Icon sx={{ fontWeight: "bold" }}>done</Icon>
        </MKTypography>
        <MKTypography variant="body2" color="text" fontWeight="regular" pl={1}>
          <b>{label}</b>
          {description} {rows} entries in {files} files.{" "}
          {onViewHandler && (
            <MKButton
              variant="outlined"
              onClick={onViewHandler}
              fullWidth
              color={"primary"}
              sx={{ marginTop: 1 }}
            >
              <Icon color="primary">{icon}</Icon>
              <MKTypography variant="body2" pl={1}>
                View first {label} entry
              </MKTypography>
            </MKButton>
          )}
        </MKTypography>
      </MKBox>
    </Card>
  );
};

Included.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  files: PropTypes.number.isRequired,
  onViewHandler: PropTypes.func,
  icon: PropTypes.object.isRequired,
};

import React from "react";

import PropTypes from "prop-types";

import { InfoWindow } from "@react-google-maps/api";
import { DataQualityIndicator } from "../DataQualityIndicator";

export const MarkerInfoWindow = ({ marker, onClose }) => {
  return (
    <InfoWindow position={{ lat: marker.firstLat, lng: marker.firstLng }} onCloseClick={onClose}>
      <DataQualityIndicator data={marker} />
    </InfoWindow>
  );
};

MarkerInfoWindow.propTypes = {
  marker: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

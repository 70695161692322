import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import { db } from "configuration/firebase";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";
import { getFormatedPrice } from "utils/price";
import { OrderListItem } from "./OrderListItem";
import { PaymentListItem } from "./PaymentListItem";

export const AdminMoney = () => {
  const [openedOrders, setOpenedOrders] = useState([]);
  const [closedOrders, setClosedOrders] = useState([]);
  const [payments, setPayments] = useState([]);
  const [money, setMoney] = useState({
    incomeClosedOrders: 0,
    outcomeDevicePayment: 0,
    incomeOpenOrders: 0,
  });

  const dispatch = useDispatch();
  const getAdminOrders = async () => {
    try {
      const consumerSnapshot = await db.collection("consumers").get();
      for await (const consumer of consumerSnapshot.docs) {
        const orderSnapshot = await db
          .collection("consumers")
          .doc(consumer.id)
          .collection("orders")
          .get();
        for await (const order of orderSnapshot.docs) {
          const data = order.data();
          if (data.status < 8)
            setOpenedOrders((current) => [...current, { ...data, ...consumer.data() }]);
          else {
            setClosedOrders((current) => [...current, { ...data, ...consumer.data() }]);
          }
        }
      }
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: error.message }));
    }
  };

  const getPayments = async () => {
    for await (const order of closedOrders) {
      const snapshot = await db
        .collection("consumers")
        .doc(order.uid)
        .collection("orders")
        .doc(order.id)
        .collection("payments")
        .get();
      if (snapshot.empty) continue;

      for await (const doc of snapshot.docs) {
        const data = doc.data();
        setPayments((current) => [...current, { ...data, ...order }]);
      }
    }
  };

  useEffect(() => {
    setMoney((current) => ({
      ...current,
      incomeClosedOrders: getFormatedPrice(
        closedOrders.reduce((total, order) => total + Number(order.price), 0)
      ),
    }));

    setMoney((current) => ({
      ...current,
      incomeOpenOrders: getFormatedPrice(
        openedOrders.reduce((total, order) => total + Number(order.price), 0)
      ),
    }));
  }, [openedOrders, closedOrders]);

  useEffect(() => {
    setMoney((current) => ({
      ...current,
      outcomeDevicePayment:
        payments.reduce((total, payment) => total + Number(payment.value), 0) / 1000000,
    }));
  }, [payments]);

  useEffect(() => {
    getAdminOrders();
  }, []);

  useEffect(() => {
    getPayments();
  }, [closedOrders]);

  return (
    <Grid container direction={"column"} mt={8}>
      <Grid item xs={12}>
        <Grid container direction={"row"} justifyContent={"space-around"} alignItems={"center"}>
          <Grid item xs={12} md={5} boxShadow={4} width={"100%"} padding={2} textAlign={"center"}>
            <MKTypography variant="h6">Income - closed orders</MKTypography>
            {/* {openedOrders.reduce((total, order) => total + Number(order.price), 0)} */}
            <b>U$ {money.incomeClosedOrders}</b>
          </Grid>
          <Grid item xs={12} md={5} boxShadow={4} width={"100%"} padding={2} textAlign={"center"}>
            <MKTypography variant="h6">Outcome - device payments</MKTypography>
            <b>U$ {money.outcomeDevicePayment}</b>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign={"center"} padding={2}>
        <MKTypography variant="h5">
          Totals: U$ {money.incomeClosedOrders - money.outcomeDevicePayment}
        </MKTypography>
      </Grid>
      <Grid item>Order list</Grid>

      {payments.length > 0 &&
        closedOrders.map((order) => (
          <Grid item xs={12} key={order.id}>
            <OrderListItem order={order} payments={payments.filter((val) => val.id === order.id)} />
          </Grid>
        ))}
    </Grid>
  );
};

import React from "react";
import { Icon } from "@mui/material";
import MKTypography from "components/MKTypography";

export const BackToTop = () => {
  return (
    <MKTypography
      component="a"
      mt={1}
      href="#"
      variant="button"
      color="info"
      fontWeight="regular"
      sx={{
        width: "max-content",
        display: "flex",
        alignItems: "center",

        "& .material-icons-round": {
          fontSize: "1.125rem",
          transform: "translateX(3px)",
          transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
        },

        "&:hover .material-icons-round, &:focus .material-icons-round": {
          transform: "translateX(6px)",
        },
      }}
    >
      Back to top
      <Icon sx={{ fontWeight: "bold" }}>arrow_upward</Icon>
    </MKTypography>
  );
};

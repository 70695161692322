import React from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import moment from "moment";
import { getFormatedPrice } from "utils/price";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PaymentListItem } from "./PaymentListItem";

export const OrderListItem = ({ order, payments }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid
          container
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={4}
          padding={2}
        >
          <Grid item>ID: {order.id}</Grid>
          <Grid item>
            Company: {order.company.companyName} - {order.company.www}
          </Grid>
          <Grid item>When: {moment.unix(order.creationTimestamp).fromNow()}</Grid>
          <Grid item>+ $ {getFormatedPrice(order.price)}</Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {payments.map((payment) => (
          <PaymentListItem payment={payment} key={payment.id} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

OrderListItem.propTypes = {
  order: PropTypes.object.isRequired,
  payments: PropTypes.array.isRequired,
};

/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

export const DataPrivacy = () => {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                color={"secondary"}
                icon="touch_app"
                title={
                  <>
                    100 %
                    <br />
                    Anonymous
                  </>
                }
                description="All the data your mobile device shares is anonymous and cannot be linked to your identity."
              />
              <RotatingCardBack
                image={bgBack}
                color={"dark"}
                title="Privacy guaranteed"
                description="Your privacy is important to us and we want to explain how we collect and share data"
                action={{
                  type: "internal",
                  route: "/docs/how",
                  label: "Read more",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="person_off"
                  direction="center"
                  title="Nothing personal"
                  description="We do not store or share any personal information. Only sensor data such as longitude, latitude, and pressure are collected."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="edit_off"
                  direction="center"
                  title="Untampered"
                  description="Data is signed with cryptographic algorithms, ensuring it is not tampered with or modified in any way."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="device_unknown"
                  direction="center"
                  title="Your data"
                  description="You retain ownership of the data generated by your device. You can always view what data is being submitted."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="account_balance_wallet"
                  title="Blockchain technology"
                  direction="center"
                  description="Data access is managed securely through blockchain technology, ensuring transparency and reliability. Payments are made in cryptocurrency for added security and convenience."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import moment from "moment";
import { Polygon } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { InfoWindow } from "@react-google-maps/api";
import { showSnackbar } from "reducer/uiReducer";
import { removeDataRequest } from "reducer/consumerReducer";
import { updateDataRequest } from "reducer/consumerReducer";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

export const DataRequestPolygon = ({ request }) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const dispatch = useDispatch();
  const polygonRef = useRef();

  const handleClick = () => {
    setShowInfoWindow(true);
  };

  const handleDrag = () => {
    // TODO this is not working. can't get the new path of the polygon after moving it
    const newPath = polygonRef.current.props.paths;
    dispatch(
      updateDataRequest({ ...request, path: newPath, lastUpdateTimestamp: moment().unix() })
    );
  };

  const handleRemove = () => {
    if (request.isNotified) {
      // we already sent notifications to users, so we won't let the user to delete it.
      dispatch(
        showSnackbar({
          severity: "error",
          message: `Notification to Bripplex users has already been sent. You can't delete this data request`,
        })
      );
      return;
    }
    dispatch(removeDataRequest(request.id));
  };

  return (
    <>
      <Polygon
        paths={request.path}
        ref={polygonRef}
        draggable={false}
        editable={false}
        options={{ clickable: true, fillColor: request.isNotified ? "green" : "#f3f70b" }}
        onDragEnd={handleDrag}
        onDblClick={handleRemove}
        onClick={handleClick}
      />
      {showInfoWindow && (
        <InfoWindow
          position={polygonRef.current.props.paths[0]}
          onCloseClick={() => setShowInfoWindow(false)}
        >
          <MKBox margin={1}>
            <NotificationsActiveIcon /> {` `}
            <MKTypography variant="h6" display={"inline"}>
              Devices notified: {request.notifications || 0}
            </MKTypography>
            {!request.isNotified && (
              <MKTypography variant="caption">Double click to delete</MKTypography>
            )}
          </MKBox>
        </InfoWindow>
      )}
      ;
    </>
  );
};

DataRequestPolygon.propTypes = {
  request: PropTypes.object.isRequired,
};

import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";

import { getDistance } from "geolib";

import { Polyline } from "@react-google-maps/api";
import { MarkerInfoWindow } from "../MarkerInfoWindow";
import { sensorsList } from "utils/sensors";

export const MapPolyline = ({ marker }) => {
  const [showWindow, setShowWindow] = useState(false);
  const [distance, setDistance] = useState(0);

  const handleMapClick = () => {
    setShowWindow(true);
  };

  const getDataScore = () => {
    const maxScore = sensorsList.length + 1;

    let score = 1; // we start at 1 because we have location
    sensorsList.map((sensor) => {
      marker[sensor.type]?.rows > 0 && score++;
    });

    if (score >= maxScore * 0.8) {
      return 5;
    } else if (score >= maxScore * 0.6) {
      return 4;
    } else if (score >= maxScore * 0.4) {
      return 3;
    } else if (score >= maxScore * 0.2) {
      return 2;
    } else {
      return 1;
    }
  };

  const [polylineOptions, setPolylineOptions] = useState({
    strokeWeight: 1,
    strokeColor: "#ff2527",
  });
  useEffect(() => {
    const score = getDataScore();
    if (score === 1) {
      setPolylineOptions({ strokeWeight: 3.7, strokeColor: "#b9696a" });
    } else if (score === 2) {
      setPolylineOptions({ strokeWeight: 4.3, strokeColor: "#db2043" });
    } else if (score === 3) {
      setPolylineOptions({ strokeWeight: 4.5, strokeColor: "#e68124" });
    } else if (score === 4) {
      setPolylineOptions({ strokeWeight: 4.7, strokeColor: "#95c908" });
    } else if (score === 5) {
      setPolylineOptions({ strokeWeight: 5.0, strokeColor: "#30c223" });
    } else {
      setPolylineOptions({ strokeWeight: 5.0, strokeColor: "#30c223" });
    }
  }, []);

  useEffect(() => {
    const val = getDistance(
      { latitude: marker.firstLat, longitude: marker.firstLng },
      { latitude: marker.lastLat, longitude: marker.lastLng },
      1
    );
    setDistance(val);
  }, []);

  return (
    <>
      {distance < 500 && (
        <Polyline
          key={marker.firstLat}
          onClick={handleMapClick}
          path={[
            { lat: marker.firstLat, lng: marker.firstLng },
            { lat: marker.lastLat, lng: marker.lastLng },
          ]}
          geodesic={true}
          options={{
            strokeColor: polylineOptions.strokeColor,
            strokeOpacity: 0.75,
            strokeWeight: polylineOptions.strokeWeight,
            icons: [
              {
                icon: { path: window.google.maps.SymbolPath.CIRCLE },
                offset: "0",
                // repeat: "20px",
              },
            ],
          }}
        />
      )}
      {showWindow && <MarkerInfoWindow marker={marker} onClose={() => setShowWindow(false)} />}
    </>
  );
};

MapPolyline.propTypes = {
  marker: PropTypes.object.isRequired,
};

import React from "react";

import PropTypes from "prop-types";

import MKTypography from "components/MKTypography";
import { Icon } from "@mui/material";
import MKBox from "components/MKBox";

export const SensorCheck = ({ name, color = "inherit", variant = "body2" }) => {
  return (
    <MKBox display="flex" alignItems={"center"}>
      <Icon color={color}>check</Icon>
      <MKTypography variant={variant} color={color}>
        {name}
      </MKTypography>
    </MKBox>
  );
};

SensorCheck.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
};

import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import { db } from "configuration/firebase";
import { NFTContainer } from "pages/Consumer/pages/MyAccount/components/MyFiles/components/NFTContainer";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "reducer/uiReducer";
import { IssuerBalance } from "./IssuerBalance";
import { USDCPayerBalance } from "./USDCPayerBalance";

export const AdminBlockchain = () => {
  const [nfts, setNfts] = useState([]);
  const dispatch = useDispatch();

  const getNFTs = async () => {
    try {
      const snapshot = await db.collection("nft").get();
      for (const doc of snapshot.docs) {
        const data = doc.data();
        setNfts((current) => [...current, data]);
      }
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: error.message }));
    }
  };
  useEffect(() => {
    getNFTs();
  }, []);

  return (
    <Grid container direction={"column"} spacing={4} mt={3}>
      <Grid item>
        <Grid container direction={"row"} justifyContent={"space-around"} alignItems={"center"}>
          <Grid item xs={12} md={5}>
            <IssuerBalance />
          </Grid>
          <Grid item xs={12} md={5}>
            <USDCPayerBalance />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <MKTypography variant="h5">NFT List</MKTypography>
      </Grid>
      {nfts.map((nft) => (
        <Grid item key={nft.tokenId}>
          <MKTypography mb={-4} variant="h6">
            Issuer: {nft.owner}
          </MKTypography>
          <NFTContainer nft={nft} />
        </Grid>
      ))}
    </Grid>
  );
};

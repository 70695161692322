import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import MKTypography from "components/MKTypography";
import moment from "moment";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AreYouSureModal } from "components/AreYouSureModal";
import { db } from "configuration/firebase";
import { showSnackbar } from "reducer/uiReducer";

export const AdminConsumer = ({ consumer }) => {
  const [approved, setApproved] = useState(consumer.company?.approved);

  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleApproved = () => {
    setShow(true);
  };
  const handleAccept = async () => {
    try {
      await db
        .collection("consumers")
        .doc(consumer.uid)
        .set({ company: { approved: !approved } }, { merge: true });
      setApproved((current) => !current);
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: error.message }));
    }
  };
  return (
    <Grid container spacing={2} padding={4}>
      <Grid item xs={12} me={4}>
        <MKTypography variant="body" textAlign="left">
          uid: {consumer.uid}
        </MKTypography>
      </Grid>
      <Grid item xs={12} md={4}>
        <MKTypography variant="body" textAlign="left">
          login {moment.unix(consumer.lastLogin).fromNow()}
        </MKTypography>
      </Grid>
      {consumer.company && (
        <>
          <Grid item xs={12}>
            <MKTypography variant="body" textAlign="left">
              Company Name: {consumer.company.companyName}
            </MKTypography>
            <MKTypography variant="body2" textAlign="left">
              www:{" "}
              <Link to={consumer.company.www} target="self">
                {" "}
                {consumer.company.companyName}
              </Link>
            </MKTypography>
            <MKTypography variant="body" textAlign="left">
              Email: {consumer.company.email}
            </MKTypography>
            <MKTypography variant="body2" textAlign="left">
              Name: {consumer.company.firstName}
              {` `} {consumer.company.lastName}
            </MKTypography>
          </Grid>
          <Grid item>
            <MKTypography variant="body" textAlign="left">
              Data usage: {consumer.company.dataUsage}
            </MKTypography>
          </Grid>
          <Grid item>
            <MKTypography variant="body2" textAlign="left">
              <FormControlLabel
                control={<Switch checked={approved} onChange={handleApproved} />}
                label="Approved"
              />
            </MKTypography>
          </Grid>
        </>
      )}
      <AreYouSureModal
        show={show}
        setShow={setShow}
        title="Are you sure?"
        message={"You want to change the approved state of this consumer?"}
        okButtonMessage="Yes"
        onAccept={handleAccept}
      />
    </Grid>
  );
};

AdminConsumer.propTypes = {
  consumer: PropTypes.object.isRequired,
};

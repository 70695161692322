import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Icon } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { db } from "configuration/firebase";
import MKTypography from "components/MKTypography";
import { NFTContainer } from "../NFTContainer/NFTContainer";
import { isMobile } from "react-device-detect";
import { shortenAddress } from "utils/web3";

export const AddressContainer = ({ address }) => {
  const [nfts, setNfts] = useState([]);
  const getNFTs = async () => {
    const query = db.collection("nft").where("owner", "==", address);
    const snapshot = await query.get();
    for (const doc of snapshot.docs) {
      setNfts((current) => [...current, doc.data()]);
    }
  };

  useEffect(() => {
    getNFTs();
  }, []);

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <MKTypography>
          <Badge
            badgeContent={nfts.length}
            color="primary"
            showZero
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Icon>account_balance_wallet</Icon>
          </Badge>
          {`   `}
          {isMobile ? shortenAddress(address) : address}
        </MKTypography>
      </AccordionSummary>
      <AccordionDetails>
        {nfts.length > 0 ? (
          nfts.map((val) => <NFTContainer nft={val} key={val.tokenId} />)
        ) : (
          <MKTypography>No NFTs</MKTypography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

AddressContainer.propTypes = {
  address: PropTypes.string.isRequired,
};

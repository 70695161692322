import React from "react";
import PropTypes from "prop-types";

import { AreYouSureModal } from "components/AreYouSureModal";
import ReactJson from "react-json-view";

export const JSONModal = ({ label, show, setShow, json }) => {
  return (
    <AreYouSureModal
      message={<ReactJson src={json} name={label} enableClipboard displayObjectSize={false} />}
      title={`First entry for ${label}`}
      show={show}
      setShow={setShow}
      onAccept={() => setShow(false)}
      okButtonMessage="Done!"
    />
  );
};

JSONModal.propTypes = {
  label: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  json: PropTypes.object.isRequired,
};
